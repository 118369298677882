import { CssBaseline, ThemeProvider as MuiThemeProvider, createTheme } from "@mui/material";

const appTheme = createTheme({
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: "var(--background-default, #fff)",
                    color: "var(--text-primary, rgba(0, 0, 0, 0.87));",
                    zIndex: 1200
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    '& .MuiBackdrop-root': {
                        backdropFilter: "blur(0.25em)",
                    }
                },
            },
        },
        MuiBreadcrumbs: {
            styleOverrides: {
                root: {
                    '& .MuiBreadcrumbs-ol': {
                        color: "var(--text-secondary, rgba(0, 0, 0, 0.60));",
                    },
                    '& .MuiBreadcrumbs-li a': {
                        color: "var(--text-secondary, rgba(0, 0, 0, 0.60));",
                        display: "inline-block",
                        textDecoration: "none"
                    },
                    '& .MuiBreadcrumbs-li a:hover': {
                        color: "var(--primary-dark, #1565C0);",
                        borderBottom: "2px solid var(--primary-dark, #1565C0);",

                    }
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    "&": {
                        wordBreak: "break-word",
                    },
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    "& .MuiFormControlLabel-label": {
                        userSelect: "none"
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    "&": {
                        transition: "transform 0.3s ease-in-out"
                    },
                    "&.flipped": {
                        transform: "rotate(-180deg)"
                    },
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    "&.topnav .MuiListItemText-primary": {
                        fontSize: "0.9375rem",
                        fontWeight: "500"
                    },
                    "&.subnav": {
                      paddingBottom: 12
                    },
                    "&.subnav .MuiListItem-root": {
                        paddingTop: 2,
                        paddingBottom: 2,
                        paddingLeft: 24
                    },
                    "&.subnav .MuiListItemText-primary": {
                        fontSize: "0.875rem",
                        fontWeight: "400"
                    }
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    "&": {
                        borderRadius: "calc(var(--border-radius, 8px) / 2)"
                    },
                    '&:hover': {
                        backgroundColor: "var(--action-hover, rgba(0, 0, 0, 0.04))"
                    },
                    "&.active": {
                        x_background: "var(--primary-_states-focus, rgba(25, 118, 210, 0.12))"
                    },
                    "&.active .MuiListItemIcon-root": {
                        color: "var(--primary-dark, #1565C0)",
                    },
                    "&.active .MuiListItemText-primary": {
                        color: "var(--primary-dark, #1565C0)",
                        fontWeight: "bold !important",
                        fontfeaturesettings: "'liga' off, 'clig' off"
                    }
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    "&": {
                        minWidth: 32
                    },
                    "& .MuiSvgIcon-root": {
                        fontSize: "var(--font-size-125-rem, 20px)"
                    },
                },
            },
        },
        MuiListItemText: {
            defaultProps: {
                primaryTypographyProps: {
                    color: "var(--text-primary, rgba(0, 0, 0, 0.87));",
                    fontfeaturesettings: "'liga' off, 'clig' off",
                    fontFamily: "var(--fontFamily, Roboto)",
                    fontSize: "var(--font-size-0875-rem, 14px)",
                    fontStyle: "normal",
                    fontWeight: "var(--fontWeightRegular, 400)",
                    lineHeight: "24px",
                    letterSpacing: "0.17px",
                    textDecoration: "none"
                }
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    "&.selected": {
                        position: "relative"
                    },
                    "&.selected::before": {
                        content: "''",
                        position: "absolute",
                        top: 2,
                        left: 0,
                        bottom: 2,
                        width: 6,
                        background: "var(--primary-dark, #1565C0)",
                    }
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    "&.content": {
                        background: "var(--background-default, #fff)",
                        padding: "16px 24px",
                        marginBottom: "24px",
                        position: "relative",
                        "--stripe-color": "var(--primary-dark)",
                    },
                    "&.content.stripe::before": {
                        content: "''",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        width: "10px",
                        background: "var(--stripe-color, #1565C0)",
                        borderRadius: "5px 0 0 5px"
                    },
                    "&.content.stripe::after": {
                        content: "''",
                        position: "absolute",
                        top: 0,
                        left: 5,
                        bottom: 0,
                        width: "6px",
                        background: "inherit",
                        borderRadius: "4px 0 0 4px"
                    },
                    "&.content h6": {
                        fontWeight: "400",
                        fontSize: "1.1rem"
                    },
                    "&.content.admin-area": {
                        background: "color-mix(in srgb, var(--primary-dark, #1565C0) 20%, var(--background-default, #FFF) 80%)",
                    }
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    "&.TwoLine .MuiSelect-select": { 
                        height: "2.8rem", 
                        whiteSpace: "normal" 
                    }, 
                    "&.TwoLine .MuiSelect-select::before": { 
                        content: "''", 
                        position: "absolute", 
                        background: "#fff", 
                        left: 0, 
                        right: 0, 
                        bottom: 0, 
                        height: "5px" 
                    }
                }
            }
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    "& .MuiTableCell-root": {
                        background: "var(--table-header-bg, rgba(0, 0, 0, 0.04))",
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    "&": {
                        wordBreak: "break-word",
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    "& input": {
                        color: "var(--text-primary, rgba(0, 0, 0, 0.87))",
                        fontfeaturesettings: "'liga' off, 'clig' off",
                        fontFamily: "var(--fontFamily, Roboto)",
                        fontSize: "var(--_fontSize-1rem, 16px)",
                        fontStyle: "normal",
                        fontWeight: "var(--fontWeightRegular, 400)",
                        lineHeight: "24px",
                        letterSpacing: "0.15px"
                    }
                },
            },
        }
    }
  });

export default function ThemeProvider({ children }) {
    return (
        <MuiThemeProvider theme={appTheme}>
            <CssBaseline />
            {children}
        </MuiThemeProvider>
    );
};
