import { Box, IconButton, Paper, Typography } from "@mui/material";
import { T } from "../../../../Util/Localize";
import ActionButtons from "../../../../Components/Input/ActionButtons";
import { useEffect, useState } from "react";
import { FormData, emptyFormData } from "../../../../Util/FormUtil";
import TextInput from "../../../../Components/Input/TextInput";
import { MultiplicationSignIcon } from "@hugeicons/react";
import SelectInput from "../../../../Components/Input/SelectInput";

interface CreateTeamMemberProps {
    isMobile?: boolean;
    onClose: any;
    team: any;
}

export default function CreateTeamMember({ isMobile, onClose, team }: CreateTeamMemberProps) {

    const [member, setMember] = useState<FormData>(emptyFormData());

    const saveData = async (updates: any, setUpdates: any) => {
    }

    useEffect(() => {
        if (member.cancel) {
            if (onClose) { onClose(); }
        }
    }, [member.cancel, onClose]);

    return (
        <Box className="panel-medium" display="flex" flexDirection="column">
            <Box className="panel-content" flex={1} display="flex" flexDirection="column">
                <Box display="flex" mb={1}>
                    <Typography variant="h5" gutterBottom sx={{ flex: 1 }}>
                        {T("Add a New Team Member")}
                    </Typography>
                    <IconButton sx={{ float: 'right', mt: -1 }} onClick={() => { if (onClose) { onClose(); } }}>
                        <MultiplicationSignIcon />
                    </IconButton>
                </Box>
                <Paper className="content">
                    <TextInput
                        name="name"
                        label={T("Name")}
                        state={[member, setMember]}
                    />
                    <TextInput
                        name="email"
                        label={T("Email Address")}
                        state={[member, setMember]}
                    />
                    <SelectInput
                        name="copy"
                        label={T("Copy Team Member Settings")}
                        state={[member, setMember]}
                        options={[['', String.fromCharCode(160)], ...team.map((m: any) => ([ m.uid, (m.firstName + " " + m.lastName).trim() ]))]}
                    />
                </Paper>
            </Box>
            <Box className="panel-footer" sx={{ px: 4, mt: 0, pb: 2 }}>
                <ActionButtons active
                    onSave={saveData} 
                    state={[member, setMember]}
                    saveLabel="Add Team Member"
                />
            </Box>
        </Box>
    );
}
