import { Avatar, Box, Button, Checkbox, CircularProgress, Divider, Drawer, FormControlLabel, IconButton, InputAdornment, ListItemIcon, 
    ListItemText, Menu, MenuItem, Paper, TextField, Tooltip, Typography } from "@mui/material";
import { T } from "../../../Util/Localize";
import { AddCircleIcon, Calendar01Icon, CheckmarkCircle02Icon, Clock05Icon, DeliveryBox01Icon, House04Icon, MapsIcon, MoreVerticalIcon, 
    MultiplicationSignCircleIcon, RemoveCircleIcon, Search02Icon, Settings01Icon, SquareArrowUpDownIcon, UserAccountIcon, UserAdd01Icon, 
    UserEdit01Icon, UserLock01Icon} from "@hugeicons/react";
import { useLoad } from "../../../Util/Hooks";
import { useState } from "react";
import { mediaSize, userTypeName } from "../../../Util/Functions";
import { alpha, useTheme } from '@mui/material/styles';
import CreateTeamMember from "./Panels/CreateTeamMember";
import { useOutletContext } from "react-router-dom";
import AddAuxTeamMember from "./Panels/AddAuxTeamMember";
import TeamMemberServiceAreas from "./Panels/TeamMemberServiceAreas";
import TeamMemberPermissions from "./Panels/TeamMemberPermissions";
import TeamMemberProductsPayouts from "./Panels/TeamMemberProductsPayouts";
import Api from "../../../Util/Api";
import UserDetails from "../../Clients/Panels/UserDetails";

export default function BusinessTeamMembers() {

    type sortModeType = 'type' | 'name' | 'aux'| 'state'| 'calendar' | 'address' | 'area' | 'bias';

    const [team, setTeam] = useState<any>(null);
    const [auxteam, setAuxTeam] = useState<any>(null);
    const [search, setSearch] = useState<string>('');
    const [displayMode, setDisplayMode] = useState<any>(JSON.parse(localStorage.getItem('teamDisplayMode') || '{ "calendar": true, "address": true, "serviceAreas": true, "schedulingBias": true, "inactive": false }'));
    const [sortMode, setSortMode] = useState<sortModeType>(localStorage.getItem('teamSort') as sortModeType || 'type');
    const [sortRev, setSortRev] = useState<boolean>(localStorage.getItem('teamSortRev') === '1' ? true : false);
    const hasAux: boolean = team?.find((m: any) => m.isAuxiliary) ? true : false;
    const theme = useTheme();
    const props: any = useOutletContext();

    const [createMemberPanel, setCreateMemberPanel] = useState(false);
    const [addAuxMemberPanel, setAddAuxMemberPanel] = useState(false);
    const [userDetailsPanel, setUserDetailsPanel] = useState(false);
    const [serviceAreasPanel, setServiceAreasPanel] = useState(false);
    const [permissionsPanel, setPermissionsPanel] = useState(false);
    const [productsPanel, setProductsPanel] = useState(false);

    const [popSettings, setPopSettings] = useState<any>(null);
    const [popSorting, setPopSorting] = useState<any>(null);
    const [popAddNew, setPopAddNew] = useState<any>(null);
    const [popEdit, setPopEdit] = useState<any>(null);
    const [popEditUser, setPopEditUser] = useState<any>(null);


    const sortOptions = {
        "type": (a: any, b: any) => { return (a.sortType - b.sortType) || (a.fullName).localeCompare((b.fullName)); },
        "name": (a: any, b: any) => { return (a.fullName).localeCompare((b.fullName)); },
        "aux": (a: any, b: any) => { return (b.isAuxiliary - a.isAuxiliary) || (a.fullName).localeCompare((b.fullName)); },
        "state": (a: any, b: any) => { return (a.state || 'zzz').localeCompare((b.state || 'zzz')) || (a.city || 'zzz').localeCompare((b.city || 'zzz')) || (a.fullName).localeCompare((b.fullName)); },
        "calendar": (a: any, b: any) => { return (b.hasCalendar - a.hasCalendar) || (a.fullName).localeCompare((b.fullName)); },
        "address": (a: any, b: any) => { return (b.hasAddress - a.hasAddress) || (a.fullName).localeCompare((b.fullName)); },
        "area": (a: any, b: any) => { return (b.hasServiceAreas - a.hasServiceAreas) || (a.fullName).localeCompare((b.fullName)); },
        "bias": (a: any, b: any) => { return (b.hasSchedulingBias - a.hasSchedulingBias) || (a.fullName).localeCompare((b.fullName)); }
    }

    const setKeyRing = (uid: number, keyRing: string) => {
        setTeam((prev: any) => {
            var member = prev.find((m: any) => m.uid === uid);
            if (member) { member.keyRing = keyRing; }
            return [...prev];
        });
    }

    const toggleDisplay = (m: string) => {
        setDisplayMode((prev: any) => {
            prev[m] = !prev[m];
            localStorage.setItem('teamDisplayMode', JSON.stringify(prev));
            return {...prev};
        });
    }

    const toggleSort = (m: sortModeType) => {
        setSortMode((prev: any) => {
            const rev = (prev === m) ? !sortRev : false
            setSortRev(rev);
            localStorage.setItem('teamSort', m);
            localStorage.setItem('teamSortRev', rev ? '1' : '0');
            return m;
        });
    }

    const teamFilter = (m: any) => {
        if ((m.status < 1) && (!displayMode.inactive)) { return false; }
        if (search.length === 0) { return true; }
        const searches = search.toLowerCase().split(' ');
        for (let i = 0; i < searches.length; i++) {
            if (searches[i].length > 0) {
                if (!m.searchable.includes(searches[i])) { return false; }
            }
        }
        return true;
    }

    useLoad(() => {
        Api.get('brand/teammembers').then((r: any) => {
            if (r.ok) { 
                const team: any = r.data.teammembers.map((m: any) => { 
                    return { ...m, 
                        fullName: (m?.firstName + " " + m?.lastName).trim() || ("User #" + m.uid),
                        searchable: (m?.firstName + " " + m?.lastName + " " + m?.city + " " + m?.state + " " + (m.isAuxiliary ? "Auxiliary" : userTypeName(m.userType))).toLowerCase(),
                        sortType: (m.status < 1 ? 20 : m.isAuxiliary ? 10 : m.userType)
                    }; 
                });
                setTeam(team);
                setAuxTeam(r.data.auxmembers);
            }
        });
    });

    return (
        <Box maxWidth="lg" sx={{ mx: 'auto' }}>
            <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                <Typography variant="h5" flex={1}>
                    {T("Team Members")}
                </Typography>
                <Box className="searchbox">
                    <TextField
                        value={search} 
                        className={(search.length ? "expanded-search" : props.isMobile ? "expandable-search" : "")}
                        onChange={(e) => { setSearch(e.target.value); }}
                        margin="normal" size="small" variant="outlined" 
                        slotProps={{ input: { endAdornment: <InputAdornment position="start"><Search02Icon size={20} /></InputAdornment> } }}
                        sx={{ m: 0, mr: 1, '& .MuiOutlinedInput-root': { borderRadius: '50px' }}} 
                    />
                </Box>
                <IconButton onClick={(e) => { setPopSettings(e.currentTarget); }}>
                    <Settings01Icon size={24} />
                </IconButton>
                <Menu 
                    anchorEl={popSettings} 
                    open={popSettings !== null} 
                    onClose={() => { setPopSettings(null); }}
                    anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Box sx={{ pt: 1, px: 2.5 }}>
                        <Typography variant="body1" sx={{ fontSize: '0.8em' }}>{T("Display")}</Typography>
                        <Box display="flex" flexDirection="column">
                            <FormControlLabel control={<Checkbox checked={displayMode.calendar} onClick={() => { toggleDisplay('calendar'); }} />} label={T("Calendar Sync")} sx={{ m: 0 }} />
                            <FormControlLabel control={<Checkbox checked={displayMode.address} onClick={() => { toggleDisplay('address'); }}  />} label={T("Home Address")} sx={{ m: 0 }} />
                            <FormControlLabel control={<Checkbox checked={displayMode.serviceAreas} onClick={() => { toggleDisplay('serviceAreas'); }}  />} label={T("Service Area")} sx={{ m: 0 }} />
                            <FormControlLabel control={<Checkbox checked={displayMode.schedulingBias} onClick={() => { toggleDisplay('schedulingBias'); }}  />} label={T("Scheduling Bias")} sx={{ m: 0 }} />
                            <Divider sx={{ my: 1 }} />
                            <FormControlLabel control={<Checkbox checked={displayMode.inactive} onClick={() => { toggleDisplay('inactive'); }} />} label={T("Inactive Team Members")} sx={{ m: 0 }} />
                        </Box>
                    </Box>
                </Menu>
                <IconButton onClick={(e) => { setPopSorting(e.currentTarget); }}>
                    <SquareArrowUpDownIcon size={24} />
                </IconButton>
                <Menu 
                    anchorEl={popSorting} 
                    open={popSorting !== null} 
                    onClose={() => { setPopSorting(null); }}
                    anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Box sx={{ py: 1, px: 2.5 }}>
                        <Typography variant="body1" sx={{ fontSize: '0.8em' }}>{T("Sort by")}</Typography>
                        <MenuItem className={sortMode === 'type' ? "selected" : ""} onClick={() => { toggleSort('type'); setPopSorting(null); }}>{T("Account Type")}</MenuItem>
                        <MenuItem className={sortMode === 'name' ? "selected" : ""} onClick={() => { toggleSort('name'); setPopSorting(null); }}>{T("Name")}</MenuItem>
                        {(hasAux) && (<MenuItem className={sortMode === 'aux' ? "selected" : ""} onClick={() => { toggleSort('aux'); setPopSorting(null); }}>{T("Auxiliary Team Member")}</MenuItem>)}
                        <MenuItem className={sortMode === 'state' ? "selected" : ""} onClick={() => { toggleSort('state'); setPopSorting(null); }}>{T("State")}</MenuItem>
                        {(!props.isMobile) && (
                            <>
                                <MenuItem className={sortMode === 'calendar' ? "selected" : ""} onClick={() => { toggleSort('calendar'); setPopSorting(null); }}>{T("Calendar Sync")}</MenuItem>
                                <MenuItem className={sortMode === 'address' ? "selected" : ""} onClick={() => { toggleSort('address'); setPopSorting(null); }}>{T("Home Address")}</MenuItem>
                                <MenuItem className={sortMode === 'area' ? "selected" : ""} onClick={() => { toggleSort('area'); setPopSorting(null); }}>{T("Service Area")}</MenuItem>
                                <MenuItem className={sortMode === 'bias' ? "selected" : ""} onClick={() => { toggleSort('bias'); setPopSorting(null); }}>{T("Scheduling Bias")}</MenuItem>
                            </>
                        )}
                    </Box>
                </Menu>
            </Box>
            <Paper className="content">
                <Button fullWidth
                    component={Paper} 
                    sx={{ px: 3, py: 2, my: 1, justifyContent: 'flex-start', textTransform: 'none' }}
                    startIcon={<AddCircleIcon size={28} />}
                    onClick={(e) => {
                        if ((auxteam || []).length > 0) {
                            setPopAddNew(e.currentTarget);
                        }
                        else {
                            setCreateMemberPanel(true);
                        }
                    }}
                >
                    <Typography variant="body1">
                        {T("Create a new team member")}
                    </Typography>
                </Button>
                <Menu 
                    anchorEl={popAddNew} 
                    open={popAddNew !== null} 
                    onClose={() => { setPopAddNew(null); }}
                    anchorOrigin={{ vertical: 'center', horizontal: 35 }}
                >
                    <Box sx={{ py: 1, px: 2.5 }}>
                        <MenuItem onClick={() => { setCreateMemberPanel(true); setPopAddNew(null); }}>
                            <ListItemIcon><UserAdd01Icon size={24} /></ListItemIcon>
                            <ListItemText>{T("Create New Team Member")}</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => { setAddAuxMemberPanel(true); setPopAddNew(null); }}>
                            <ListItemIcon><UserAccountIcon size={24} /></ListItemIcon>
                            <ListItemText>{T("Add New Auxiliary Team Member")}</ListItemText>
                        </MenuItem>
                    </Box>
                </Menu>
                {(team === null) ? (
                    <Box display="flex" alignItems="center" justifyContent="center" sx={{ my: 4 }}>
                        <CircularProgress color="inherit" size={32} sx={{ opacity: 0.25, mr: 2 }} />
                        <Typography variant="body1" sx={{ opacity: 0.5 }}>{T("Loading...")}</Typography>
                    </Box>
                ) : (
                    team.filter(teamFilter).sort(sortRev ? (a: any, b: any) => sortOptions[sortMode](b, a) : sortOptions[sortMode]).map((item: any) => (
                        <Box component={Paper} key={item.uid} 
                            sx={{ py: 1, my: 1, cursor: 'pointer', display: 'flex', alignItems: 'center', ...((!props.isMobile) ? { pl: 3, pr: 1.5 } : { pl: 2, pr: 1 }), ...((item.status < 1) ? { background: alpha(theme.palette.error.main, 0.07) } : {}) }} 
                            onClick={() => { setUserDetailsPanel(true); }}
                        >
                            <Box display="flex" alignItems="center" flex={1}>
                                <Avatar
                                    src={(item.media) ? "https://media.hd.pics/" + mediaSize(item.media, 100) + "." + item.ext + "?w=60" : ""}
                                    alt={item.fullName}
                                    sx={{ width: 50, height: 50 }}
                                />
                                <Box sx={{ mx: 1.5 }}>
                                    <Box sx={{ backgroundColor: item.color ? ('#' + item.color) : '#444', width: 4, height: (!props.isMobile? 38 : 48), borderRadius: 2  }}></Box>
                                </Box>
                                <Box>
                                    {(!props.isMobile) ? (
                                        <>
                                            <Box display="flex" alignItems="flex-end">
                                                <Typography variant="body1" fontWeight="bold" sx={{ fontSize: '1.1em' }}>{item.fullName}</Typography>
                                                <Typography variant="body1" mx={1}>|</Typography>
                                                {(item.status < 1) ? (
                                                    <Typography variant="body1" color="error">{T((item.status === -1) ? "Archived" : "Inactive")}</Typography>
                                                ) : (
                                                    <Typography variant="body1">{T(item.isAuxiliary ? "Auxiliary Team Member" : userTypeName(item.userType))}</Typography>
                                                )}
                                            </Box>
                                            <Typography variant="body2" sx={{ opacity: 0.6, mt: -0.5 }}>{[item.city, item.state].filter((x: any) => x).join(', ')}</Typography>
                                        </>
                                    ) : (
                                        <>
                                            <Typography variant="body1" fontWeight="bold">{item.fullName}</Typography>
                                            <Typography variant="body1" sx={{ fontSize: '0.9em', mt: -0.5 }}>{userTypeName(item.userType)}</Typography>
                                            <Typography variant="body2" sx={{ fontSize: '0.85em', opacity: 0.6, mt: -0.5 }}>{[item.city, item.state].filter((x: any) => x).join(', ')}</Typography>
                                        </>
                                    )}
                                </Box>
                            </Box>
                            {(!props.isMobile) && [
                                {
                                    title: "Calendar Sync",
                                    icon: <Calendar01Icon opacity={0.7} size={24} />,
                                    hasItem: item.hasCalendar,
                                    show: displayMode.calendar
                                },
                                {
                                    title: "Address",
                                    icon: <House04Icon opacity={0.7} size={24} />,
                                    hasItem: item.hasAddress,
                                    show: displayMode.address
                                },
                                {
                                    title: "Service Map",
                                    icon: <MapsIcon opacity={0.7} size={24} />,
                                    hasItem: item.hasServiceAreas,
                                    show: displayMode.serviceAreas
                                },
                                {
                                    title: "Scheduling Bias",
                                    icon: <Clock05Icon opacity={0.7} size={24} />,
                                    hasItem: item.hasSchedulingBias,
                                    show: displayMode.schedulingBias
                                }
                            ].filter((o) => o.show).map((icon: any) => (
                                <Tooltip placement="top" arrow title={T(icon.title)} key={icon.title}>
                                    <Box display="flex" alignItems="center" mx={2} sx={{ position: 'relative' }}>
                                        {icon.icon}
                                        <Box display="flex" alignItems="center" sx={{ position: 'absolute', top: 11, left: 15, background: '#fff', borderRadius: '50%' }}>
                                            {(icon.hasItem) ? (
                                                <CheckmarkCircle02Icon color={theme.palette.success.main} variant="solid" size={14}></CheckmarkCircle02Icon>
                                            ) : (
                                                <MultiplicationSignCircleIcon color={theme.palette.error.main} variant="solid" size={14}></MultiplicationSignCircleIcon>
                                            )}
                                        </Box>
                                    </Box>
                                </Tooltip>
                            ))}
                            <IconButton onClick={(e) => { setPopEdit(e.currentTarget); setPopEditUser(item); e.preventDefault(); e.stopPropagation(); }}>
                                <MoreVerticalIcon size={24} />
                            </IconButton>
                        </Box>
                    ))
                )}
                <Menu 
                    anchorEl={popEdit} 
                    open={popEdit !== null} 
                    onClose={() => { setPopEdit(null); }}
                    anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Box sx={{ py: 1, px: 2.5 }}>
                        <MenuItem onClick={() => { setUserDetailsPanel(true); setPopEdit(null); }}>
                            <ListItemIcon><UserEdit01Icon size={24} /></ListItemIcon>
                            <ListItemText>{T("Edit Team Member Details")}</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => { setServiceAreasPanel(true); setPopEdit(null); }}>
                            <ListItemIcon><MapsIcon size={24} /></ListItemIcon>
                            <ListItemText>{T("Configure Service Area")}</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => { setPermissionsPanel(true); setPopEdit(null); }}>
                            <ListItemIcon><UserLock01Icon size={24} /></ListItemIcon>
                            <ListItemText>{T("Edit Permissions")}</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => { setProductsPanel(true); setPopEdit(null); }}>
                            <ListItemIcon><DeliveryBox01Icon size={24} /></ListItemIcon>
                            <ListItemText>{T("Configure Products/Payouts")}</ListItemText>
                        </MenuItem>
                        {popEditUser?.isAuxiliary && (
                            <>
                                <Divider sx={{ my: 1 }} />
                                <MenuItem onClick={() => { setPopEdit(null); }}>
                                    <ListItemIcon><RemoveCircleIcon size={24} /></ListItemIcon>
                                    <ListItemText>{T("Remove Auxiliary Team Member")}</ListItemText>
                                </MenuItem>
                            </>
                        )}
                    </Box>
                </Menu>
            </Paper>
            <Drawer anchor="right" open={createMemberPanel} onClose={() => { setCreateMemberPanel(false); }}>
                <CreateTeamMember team={team} isMobile={props.isMobile} onClose={() => { setCreateMemberPanel(false); }} />
            </Drawer>
            <Drawer anchor="right" open={addAuxMemberPanel} onClose={() => { setAddAuxMemberPanel(false); }}>
                <AddAuxTeamMember auxmembers={auxteam} isMobile={props.isMobile} onClose={() => { setAddAuxMemberPanel(false); }} />
            </Drawer>
            <Drawer anchor="right" open={userDetailsPanel} onClose={() => { setUserDetailsPanel(false); }}>
                <UserDetails userId={popEditUser?.uid} isMobile={props.isMobile} onClose={() => { setUserDetailsPanel(false); }} />
            </Drawer>
            <Drawer anchor="right" open={serviceAreasPanel} onClose={() => { setServiceAreasPanel(false); }}>
                <TeamMemberServiceAreas userId={popEditUser?.uid} userName={popEditUser?.fullName} isMobile={props.isMobile} onClose={() => { setServiceAreasPanel(false); }} />
            </Drawer>
            <Drawer anchor="right" open={permissionsPanel} onClose={() => { setPermissionsPanel(false); }}>
                <TeamMemberPermissions userId={popEditUser?.uid} userName={popEditUser?.fullName} keyRing={popEditUser?.keyRing} isMobile={props.isMobile} onSetKeyRing={setKeyRing} onClose={() => { setPermissionsPanel(false); }} />
            </Drawer>
            <Drawer anchor="right" open={productsPanel} onClose={() => { setProductsPanel(false); }}>
                <TeamMemberProductsPayouts userId={popEditUser?.uid} userName={popEditUser?.fullName} isMobile={props.isMobile} onClose={() => { setProductsPanel(false); }} />
            </Drawer>
        </Box>
    );
}
