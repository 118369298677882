import { useEffect, useState } from 'react';
import { emptyFormData } from "../../Util/FormUtil";
import { Box, FormControlLabel, Skeleton, Switch, Typography } from '@mui/material';

interface KeyRingItemProps {
    name: string,
    field?: string,
    label: string,
    description?: string,
    defaultChecked?: boolean,
    disabled?: boolean,
    underline?: boolean,
    rightElement?: any,
    sx?: any,
    data?: any
    state?: any,
    onChange?: any,
    className?: string,
    isLoading?: boolean
}
export default function KeyRingItem(props: KeyRingItemProps) {

    const defaultChecked: boolean = (props.defaultChecked || (props.data && props.data[props.field || 'keyRing'] && props.data[props.field || 'keyRing'].split(',').indexOf(props.name) !== -1) || false);
    const [checked, setChecked] = useState(defaultChecked); 
    const [data, setData] = props.state ? props.state : [null, () => {}];
    const cancel = data && data.cancel;

    let merged: any = {
        sx: { m: 0 },
        ...props, 
        disabled: data && data.saving ? true : props.disabled,
        onChange: (e: any) => { 
            const val: boolean = e.target.checked;
            const mod: boolean = val !== defaultChecked
            setData((prev: any) => {
                const field = defaultChecked ? 'removeKeyRing' : 'addKeyRing';
                var list: any;
                if (mod) {
                    list = prev.data[field] ? prev.data[field].split(',') : [];
                    if (list.indexOf(props.name) === -1) { list.push(props.name); }
                    prev.data[field] = list.join(',');
                    prev.modified = true; 
                }
                else {
                    if (prev.data[field]) {
                        list = prev.data[field].split(',').filter((item: any) => item !== props.name);
                        if (list.length === 0) { 
                            delete prev.data[field]; 
                        }
                        else { 
                            prev.data[field] = list.join(','); 
                        }
                    }
                }
                prev.modified = Object.keys(prev.data).length > 0;
                return {...prev};
            });
            setChecked(val);
            if (props.onChange) {
                props.onChange(e, mod); 
            }
        },
        className: (props.className || "") + ((data && data.data[props.name] !== undefined) ? " modified" : "").trim()
    };
    delete merged.state;
    delete merged.isLoading;
    delete merged.data;
    delete merged.label;
    delete merged.underline;
    delete merged.rightElement;

    useEffect(() => { setChecked(defaultChecked); }, [defaultChecked]);

    useEffect(() => {
        if (cancel) { 
            setData(emptyFormData());
            setChecked(defaultChecked);
        }
    }, [cancel, defaultChecked, setData]);

    return (props.isLoading) ? (
        <Box display="flex" alignItems="flex-start" sx={{ pt: 2, pb: 1 }} >
            <Box sx={{ flexGrow: 1 }}>
                <Skeleton variant="rounded" height="1.5rem" width="100%" sx={{ mb: 1 }} />
                <Skeleton variant="rounded" height="1rem" width="100%" />
            </Box>
            <Skeleton variant="rounded" height="1.5rem" width="3rem" sx={{ ml: 2 }} />
        </Box>
    ) : (
        <Box display="flex" alignItems="flex-start" py={2} sx={{ borderBottom: props.underline ? '1px solid #ccc' : 'none', ...props.sx }}>
            <Box flex={1} sx={{ cursor: 'pointer', userSelect: 'none' }} onClick={() => setChecked(!checked)}>
                <Typography variant="body1" fontWeight="500" color="text.primary">
                    {props.label}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {props.description}
                </Typography>
            </Box>
            <Box>
                <FormControlLabel {...merged}
                    control={<Switch checked={checked} />}
                />
            </Box>
            {props.rightElement}
        </Box>
    );
}
