import { Box, Collapse, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import { DashboardSquareAddIcon, Album01Icon, UserMultipleIcon, ListViewIcon, MapPinpoint02Icon, BankIcon, UserCircle02Icon, 
    CustomerSupportIcon, SecurityLockIcon, ArrowUp01Icon, 
    ShoppingCart01Icon, UserGroupIcon, 
    Briefcase01Icon} from "@hugeicons/react";
import { T } from "../../Util/Localize";
import { useEffect, useState } from "react";
import Api from "../../Util/Api";


export default function NavPanel() {

    type NavItem = { name: string, path: string, icon?: any, userTypes?: number[], show?: (user: any) => boolean, items?: NavItem[] };

    const nav: NavItem[] = [
        { name: 'Management', path: '' },
        { name: 'Dashboard', path: '/ux/Dashboard', icon: <DashboardSquareAddIcon size={20} /> },
        { name: 'Sites', path: '/ux/Sites', icon: <Album01Icon size={20} /> },
        { name: 'Clients', path: '/ux/Clients', icon: <UserMultipleIcon size={20} />, show: (u: any) => { return ([1, 2, 4].includes(u.userType) || u.roles.includes('nav-clients')); }},
        { name: 'Groups', path: '/ux/Groups', icon: <ListViewIcon size={20} />, show: (u: any) => { return ([1, 2, 4].includes(u.userType) || u.roles.includes('nav-clients')); }},
        { name: 'Businesses', path: '/ux/Businesses', icon: <MapPinpoint02Icon size={20} />, show: (u: any) => { return ((u.userType === 1) || ([2, 2].includes(u.userType) && u.isParentBrand)); }},
        { name: 'Business', path: '', show: (u: any) => { return ((u.userId === 1) || (u.userType === 2) || (u.roles.includes('nav-brand'))); } },
        { name: 'Business Operations', path: '/ux/Business/Operations', icon: <BankIcon size={20} />,
            items: [
                { name: 'Business Summary', path: '/ux/Business/Operations/Summary', show: (u: any) => { return ((u.userId === 1) || (u.userType === 2) || (u.roles.includes('nav-brand'))); }},
                { name: 'Bulk Delivery', path: '/ux/Business/Operations/BulkDelivery', show: (u: any) => { return (u.userType === 2) || (u.roles.includes('brand-bulkactivate')); } },
                { name: 'Reports', path: '/ux/Business/Operations/Reports',show: (u: any) => { return (u.userType === 2) || (u.roles.includes('brand-reports')); } },
            ]
        },
        { name: 'Business Settings', path: '/ux/Business/Settings/', icon: <Briefcase01Icon size={20} />,
            items: [
                { name: 'Business Details', path: '/ux/Business/Settings/Details', show: (u: any) => { return (u.userType === 2) || (u.roles.includes('nav-config')); } },
                { name: 'Configuration', path: '/ux/Business/Settings/Config', show: (u: any) => { return (u.userType === 2) || (u.roles.includes('nav-defaults')); } },
                { name: 'Branding', path: '/ux/Business/Settings/Branding', show: (u: any) => { return (u.userType === 2) || (u.roles.includes('nav-branding')); } },
                { name: 'Email Messages', path: '/ux/Business/Settings/EmailMessages', show: (u: any) => { return (u.userType === 2) || (u.roles.includes('nav-messages')); } },
                { name: 'Domains', path: '/ux/Business/Settings/Domains', show: (u: any) => { return (u.userType === 2) || (u.roles.includes('nav-domains')); } },
                { name: 'Mobile App', path: '/ux/Business/Settings/MobileApp', show: (u: any) => { return (u.isAdmin) || (u.userType === 2) || (u.roles.includes('mobileapp')) || (u.roles.includes('nav-mobileapp')); } },
                { name: 'App Integrations', path: '/ux/Business/Settings/AppIntegrations', show: (u: any) => { return (u.userType === 2) || (u.roles.includes('nav-apps')); } },
                { name: 'Custom Designs', path: '/ux/Business/Settings/CustomDesigns', show: (u: any) => { return (u.userType === 2) || (u.roles.includes('nav-designs')); } },
                { name: 'Business Pages', path: '/ux/Business/Settings/Pages', show: (u: any) => { return (u.userType === 2) || (u.roles.includes('nav-pages')); } },
                { name: 'Webhooks/APIs', path: '/ux/Business/Settings/WebhooksAPIs', show: (u: any) => { return (u.userType === 2) || (u.roles.includes('brand-api')); } },
            ]
        },
        { name: 'Team Members', path: '/ux/Business/Team/', icon: <UserGroupIcon size={20} />,
            items: [
                { name: 'Team Members', path: '/ux/Business/Team/Members', show: (u: any) => { return (u.userType === 2) || (u.roles.includes('nav-team')); } },
                { name: 'Pay Team', path: '/ux/Business/Team/PayTeam', show: (u: any) => { return (u.userType === 2) || (u.roles.includes('cart-payout')); } },
                { name: 'Market Areas', path: '/ux/Business/Team/MarketAreas', show: (u: any) => { return (u.roles.includes('markets')) && (u.roles.includes('brand-markets')); } },
                { name: 'Site Attribution', path: '/ux/Business/Team/SiteAttribution', show: (u: any) => { return ((u.userType === 2) || (u.roles.includes('nav-sponsors'))) && (!u.roles.includes('site-disablepropertysite')); } },
            ]
        },
        { name: 'Shopping Cart', path: '/ux/Business/Cart/', icon: <ShoppingCart01Icon size={20} />,
            items: [
                { name: 'Products', path: '/ux/Business/Cart/Products', show: (u: any) => { return (u.userType === 2) || (u.roles.includes('cart')); } },
                { name: 'Price Tiers', path: '/ux/Business/Cart/PriceTiers', show: (u: any) => { return (u.userType === 2) || (u.roles.includes('cart')); } },
                { name: 'Upsells', path: '/ux/Business/Cart/Upsells', show: (u: any) => { return (u.userType === 2) || (u.roles.includes('cart')); } },
                { name: 'Promo Codes', path: '/ux/Business/Cart/PromoCodes', show: (u: any) => { return (u.userType === 2) || (u.roles.includes('cart')); } },
                { name: 'Workflow Tasks', path: '/ux/Business/Cart/WorkflowTasks', show: (u: any) => { return (u.userType === 2) || (u.roles.includes('cart')); } },
                { name: 'Detail Questions', path: '/ux/Business/Cart/DetailQuestions', show: (u: any) => { return (u.userType === 2) || (u.roles.includes('cart')); } },
                { name: 'Product Filters', path: '/ux/Business/Cart/ProductFilters', show: (u: any) => { return (u.userType === 2) || (u.roles.includes('cart')); } },
                { name: 'Service Areas', path: '/ux/Business/Cart/ServiceAreas', show: (u: any) => { return (u.userType === 2) || (u.roles.includes('brand-serviceareas')); } },
                { name: 'Product Fees', path: '/ux/Business/Cart/ProductFees', show: (u: any) => { return (u.roles.includes('productfees')) && (u.roles.includes('brand-fees')); } },
                { name: 'Sales Tax', path: '/ux/Business/Cart/SalesTax', show: (u: any) => { return ([1, 2].includes(u.userType)) || (u.roles.includes('cart')); } },
            ]},
        { name: 'Account', path: '' },
        { name: 'Account Settings', path: '/ux/Account', icon: <UserCircle02Icon size={20} />,
            items: [
                { name: 'Basic Data', path: '/ux/Account/Basic' },
                { name: 'Earnings', path: '/ux/Account/Earnings', userTypes: [2, 3] },
                { name: 'Listings Pages', path: '/ux/Account/ListingPages', show: (u: any) => { return (!u.roles.includes('site-disablepropertysite')) || u.roles.includes('site-enablepropertysite'); } },
                { name: 'Reports', path: '/ux/Account/Reports' },
                { name: 'Payment Types', path: '/ux/Account/PaymentTypes', show: (u: any) => { return ([1, 4, 5, 6].includes(u.userType)) && (u.hasPaymentProcessor); } },
                { name: 'Bulk Payment', path: '/ux/Account/BulkPayment', show: (u: any) => { return ([4, 5, 6].includes(u.userType)) && (u.hasPaymentProcessor); } },
                { name: 'Update Password', path: '/ux/Account/Password' },
                { name: 'App Integrations', path: '/ux/Account/AppIntegrations', userTypes: [1, 2, 3] },
                { name: 'Service Areas', path: '/ux/Account/ServiceAreas', show: (u: any) => { return (u.roles.includes('servicearea-basic') ? !u.roles.includes('servicearea-disablebasic') : u.roles.includes('servicearea-enablebasic')) || (u.roles.includes('servicearea-view') ? !u.roles.includes('servicearea-disableview') : u.roles.includes('servicearea-enableview')); } },
            ]
        },
        { name: 'Support', path: '/ux/Support', icon: <CustomerSupportIcon size={20} />, userTypes: [2, 3, 4, 5, 6] },
        { name: 'Administration', path: '', userTypes: [1] },
        { name: 'Admin Area', path: '/ux/Admin', icon: <SecurityLockIcon size={20} />, userTypes: [1] },
    ]

    const [opens, setOpens] = useState<any>({});
    const path = useLocation().pathname;

    useEffect(() => {
        setOpens({});
    }, [path]);

    const filterMenu = (item: any) => {
        if (item.userTypes && !item.userTypes.includes(Api.user.userType)) { return false; }
        if (item.show && !item.show(Api.user)) { return false; }
        return true;
    }

    const toggleMenu = (path: string) => {
        setOpens({ ...opens, [path]: !opens[path] });
    }

    return (
        <List component="nav" className="topnav" disablePadding>
            {nav.filter(filterMenu).map((item: any, index: number) => {
                const forceOpen = path.startsWith(item.path);
                return (item.path === '') ? (
                    <Typography key={index} sx={{ fontSize: 11, fontWeight: 'bold', opacity: 0.4, textTransform: 'uppercase', mt: (index ? 3 : 1), ml: 1.8 }}>{T(item.name)}</Typography>
                ) :(!item.items) ? (
                    <ListItem key={index} component={NavLink} to={item.path}>
                        {(item.icon) && (<ListItemIcon>{item.icon}</ListItemIcon>)}
                        <ListItemText primary={T(item.name)} />
                    </ListItem>
                ) : (
                    <Box key={index}>
                        <ListItemButton disableRipple={forceOpen} onClick={() => { if (!forceOpen) { toggleMenu(item.path); }}}>
                            {(item.icon) && (<ListItemIcon>{item.icon}</ListItemIcon>)}
                            <ListItemText primary={T(item.name)} />
                            <IconButton disabled className={(forceOpen || opens[item.path]) ? "flipped" : ""} sx={{ mx: -2 }}>
                                <ArrowUp01Icon />
                            </IconButton>
                        </ListItemButton>
                        <Collapse in={forceOpen || opens[item.path]} timeout="auto" unmountOnExit>
                            <Box display="flex">
                                <Box flex={1} ml={3.25}>
                                    <List component="div" className="subnav" disablePadding>
                                        {item.items.map((subitem: any, subindex: number) => (
                                            <ListItem key={subindex} component={NavLink} to={subitem.path}>
                                                {(subitem.icon) && (<ListItemIcon>{subitem.icon}</ListItemIcon>)}
                                                <ListItemText primary={T(subitem.name)} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            </Box>
                        </Collapse>
                    </Box>
                );
            })}
        </List>
    );
}
