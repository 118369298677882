import { Box, Button, CircularProgress } from '@mui/material';
import { Tick02Icon, MultiplicationSignIcon } from '@hugeicons/react';
import { useState } from 'react';

interface ButtonApiProps {
    run: any;
    start?: any;
    processing?: any;
    success?: any;
    fail?: any;
}

type buttonState = 'start' | 'processing' | 'success' | 'fail';

export default function ButtonApi(props: ButtonApiProps) {

    const [state, setState] = useState<buttonState>('start');
    const def = { sx: { flex: 1, ml: 2, textTransform: 'none' } };
    const startProps = props.start || { variant: 'outlined', color: 'primary', label: 'Start' };
    const processingProps = props.processing || { variant: 'outlined', color: 'primary', label: 'Processing', startIcon: <CircularProgress size={20} /> };
    const successProps = props.success || { variant: 'contained', color: 'success', label: 'Succeeded', startIcon: <Tick02Icon variant="solid" size={20} /> };
    const failProps = props.fail || { variant: 'contained', color: 'error', label: 'Failed', startIcon: <MultiplicationSignIcon variant="solid" size={20} /> };


    const startProcessing = () => {
        setState('processing');
        props.run(() => { setState('success'); }, (ms: number | undefined) => { setState('fail'); if (ms) { setTimeout(() => { setState('start'); }, ms); } }, () => { setState('start'); });
    }

    return (
        <Box display="flex" flexDirection="column">
            <Box sx={{ display: 'flex', overflow: 'hidden', height: (state === 'start' ? 'auto' : 0) }}><Button {...{...def, ...startProps}} onClick={startProcessing}>{startProps.label}</Button></Box>            
            <Box sx={{ display: 'flex', overflow: 'hidden', height: (state === 'processing' ? 'auto' : 0) }}><Button {...{...def, ...processingProps}}>{processingProps.label}</Button></Box>
            <Box sx={{ display: 'flex', overflow: 'hidden', height: (state === 'success' ? 'auto' : 0) }}><Button {...{...def, ...successProps}}>{successProps.label}</Button></Box>
            <Box sx={{ display: 'flex', overflow: 'hidden', height: (state === 'fail' ? 'auto' : 0) }}><Button {...{...def, ...failProps}}>{failProps.label}</Button></Box>
        </Box>
    );
}
