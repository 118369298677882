import { useEffect, useState } from "react";
import { emptyFormData } from "../../Util/FormUtil";
import { Box, FormControl, InputLabel, MenuItem, Select, Skeleton } from "@mui/material";

export default function SelectInput(props: any) {

    const null2Empty = (v: any) => { return v === null ? "" : v; };
    const defaultValue = null2Empty((props.defaultValue !== undefined) ? props.defaultValue : (props.data && props.data[props.name] !== undefined ? props.data[props.name] : ""));
    const [value, setValue] = useState(defaultValue); 
    const [data, setData] = props.state ? props.state : [null, () => {}];
    const def: any = { displayEmpty: true, fullWidth: true, margin: "normal", size: "small", variant: "outlined" };
    const cancel = data && data.cancel;
    const options = !props.options ? [] : Array.isArray(props.options) ? props.options.map((v: any) => { return Array.isArray(v) ? [v[0] === null ? "" : v[0], v[1]] : [v === null ? "" : v, v] }) : Object.keys(props.options).map((key: any) => { return [key, props.options[key]]; });
    const failoverValue = null2Empty((options.filter((opt: any) => String(opt[0]) === String(value)).length === 0) ? options[0][0] : value);

    let merged: any = {...def, 
        ...props, 
        disabled: (data && data.saving ? true : props.disabled) || false,
        onChange: (e: any) => { 
            const val = e.target.value;
            setValue(val);
            const mod = val !== defaultValue
            if (props.name) {
                setData((prev: any) => {
                    if (mod) { 
                        prev.data[props.name] = (val === "") ? null : val;
                        prev.modified = true; 
                    }
                    else {
                        delete prev.data[props.name];
                        prev.modified = Object.keys(prev.data).length > 0;
                    }
                    return {...prev};
                });
            }
            if (props.onChange) {
                props.onChange(e, (mod) ? val : undefined); 
            }
        },
        className: (props.className || "") + ((data && data.data[props.name] !== undefined) ? " modified" : "").trim()
    };
    delete merged.state;
    delete merged.isLoading;
    delete merged.data;

    useEffect (() => {
        setValue(defaultValue);    
    }, [defaultValue]);

    useEffect(() => {
        if (cancel) { 
            setValue(defaultValue);
            setData(emptyFormData());
        }
    }, [cancel, defaultValue, setData]);

    return (props.isLoading || false) ? (
        <Box sx={{ pt: 2, pb: 1 }} >
            <Skeleton variant="rounded" height={"2.5rem"} />
        </Box>
    ) : (
        <FormControl fullWidth={merged.fullWidth} margin={merged.margin} size={merged.size} variant={merged.variant}
            className={(data && data.data[props.name] !== undefined) ? "modified" : ""}>
            <InputLabel shrink={value !== undefined}>{props.label}</InputLabel>
            <Select {...{...merged, margin: "none", value: failoverValue }}>
                {
                    options.map((opt: any) => {
                        return <MenuItem key={opt[0]} value={opt[0]}>{opt[1]}</MenuItem>;
                    })
                }
            </Select>
        </FormControl>
    );
}
