import { useEffect, useState } from "react";
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import Api from "../../Util/Api";
import { Download04Icon, Upload04Icon } from "@hugeicons/react";

export default function AdminPanelAPI() {

    const [apiCalls, setApiCalls] = useState(Api.apiLog);

    useEffect(() => {
        Api.apiNotify = (list: any) => { setApiCalls([...list]); }
        return () => { Api.apiNotify = null; }
    }, []);

    return (apiCalls.length === 0) ? (
        <Box>No API calls</Box>
    ) : (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>Page</TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>Method</TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>Endpoint</TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>Status</TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>Time</TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>Data</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {apiCalls.map((call: any, index: number) => (
                    <TableRow key={index}>
                        <TableCell>
                            <Tooltip placement="top" arrow title={call.page}>
                                <Typography color="#8fc" variant="caption" className="truncate-front" width="100%" maxWidth="65px">
                                    {call.page}
                                </Typography>
                            </Tooltip>
                        </TableCell>
                        <TableCell>{call.method}</TableCell>
                        <TableCell>
                            <Tooltip placement="top" arrow title={call.url}>
                                <Typography color="#fc8" variant="caption" className="truncate" width="100%" maxWidth="100px">
                                    {call.url}
                                </Typography>
                            </Tooltip>
                        </TableCell>
                        <TableCell>{call.status}</TableCell>
                        <TableCell>
                            <Typography color="#8cf" variant="caption">
                                {(call.duration < 1000) ? call.duration + "ms" : Number(call.duration / 1000).toFixed(call.duration > 100000 ? 0 : call.duration > 10000 ? 1 : 2) + "s"}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Box display="flex" gap={0.5}>
                                {(call.payload) && (
                                    <Tooltip placement="left" arrow title={JSON.stringify(call.payload)}>
                                        <Upload04Icon size={16} />
                                    </Tooltip>
                                )}
                                {(call.data) && (
                                    <Tooltip placement="left" arrow title={JSON.stringify(call.data)}>
                                        <Download04Icon size={16} />
                                    </Tooltip>
                                )}
                            </Box>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}
