import { useState } from "react";
import { useLoad } from "../../../Util/Hooks";
import { Box, Button, Drawer, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { T } from "../../../Util/Localize";
import { FormData, emptyFormData, errorData } from "../../../Util/FormUtil";
import { clipboardSetText, getBanners, snack } from "../../../Util/Functions";
import { Link, useOutletContext } from 'react-router-dom';
import Api from "../../../Util/Api";
import NoAccess from "../../../Components/Layout/NoAccess";
import PaperCollapse from "../../../Components/Widget/PaperCollapse";
import SelectInput from "../../../Components/Input/SelectInput";
import TextInput from "../../../Components/Input/TextInput";
import ActionButtons from "../../../Components/Input/ActionButtons";
import KeyRingItem from "../../../Components/Widget/KeyRingItem";
import ButtonApi from "../../../Components/Widget/ButtonApi";
import MarketingKitPageDesign from "./Panels/MarketingKitPageDesign";
import { Search02Icon, Share08Icon, ArrowRight01Icon, Settings01Icon, SidebarTopIcon, MessageMultiple01Icon, WorkUpdateIcon, Wallet01Icon, MoreHorizontalSquare02Icon, 
    UserCheck01Icon, SlidersHorizontalIcon, House04Icon, ToggleOffIcon, NoteEditIcon, CellsIcon, PrisonGuardIcon, Notification01Icon, PlusSignCircleIcon, 
    ConnectIcon, MinusSignCircleIcon, MailEdit02Icon, 
    Tick02Icon} from "@hugeicons/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare, faSquareXTwitter, faLinkedin, faPinterestSquare } from "@fortawesome/free-brands-svg-icons";
import { DialogHook, useDialogs } from "@toolpad/core";

export default function BusinessSettingsConfiguration() {

    const defHash = window.location.hash.substring(1);
    const defSection = ((defHash.substring(0, 2).toLowerCase() === 's:') ? "" : defHash);
    const [search, setSearch] = useState((defHash.substring(0, 2).toLowerCase() === 's:') ? defHash.substring(2) : "");
    const [data, setData] = useState<any>(null);
    const [platformSettings, setPlatformSettings] = useState<FormData>(emptyFormData());
    const [newSiteDefaults, setNewSiteDefaults] = useState<FormData>(emptyFormData());
    const [automatedCommunication, setAutomatedCommunication] = useState<FormData>(emptyFormData());
    const [newClientDefaults, setNewClientDefaults] = useState<FormData>(emptyFormData("newClientDefaults"));
    const [billingOptions, setBillingOptions] = useState<FormData>(emptyFormData());
    const [platformFeatures, setPlatformFeatures] = useState<FormData>(emptyFormData());
    const [teamMemberSettings, setTeamMemberSettings] = useState<FormData>(emptyFormData());
    const [integrationSettings, setIntegrationSettings] = useState<FormData>(emptyFormData());
    const [mlsCompliance, setMlsCompliance] = useState<FormData>(emptyFormData());
    const [disabledFeatures, setDisabledFeatures] = useState<FormData>(emptyFormData());
    const [other, setOther] = useState<FormData>(emptyFormData());
    const [pushNotifications, setPushNotifications] = useState<FormData>(emptyFormData());
    const [optionalModules, setOptionalModules] = useState<FormData>(emptyFormData());
    const [majorBrandSpecific, setMajorBrandSpecific] = useState<FormData>(emptyFormData());
    const [additions, setAdditions] = useState<FormData>(emptyFormData());
    const [subtractions, setSubtractions] = useState<FormData>(emptyFormData());
    const [marketingKitPanel, setMarketingKitPanel] = useState(defHash === 'MarketingKitPageDesign');
    const [depositType, setDepositType] = useState(1);
    const isLoading: boolean = (data === null);
    const inputDefaults: any = { data, isLoading };
    const billableId = newClientDefaults?.data?.billableId || data?.billableId || 1;
    const socialDisable = ((platformSettings.data.socialDisable !== undefined) ? platformSettings.data.socialDisable.split(',') : data?.socialDisable ? data.socialDisable.split(',') : []);
    const dialogs: DialogHook = useDialogs();
    const props: any = useOutletContext();

    const rescheduleOptions = [
        [null, "Don't Allow Clients to Reschedule"],
        [0, "At any time before the appointment"],
        [1, "1 hour before the appointment"],
        [2, "2 hours before the appointment"],
        [4, "4 hours before the appointment"],
        [8, "8 hours before the appointment"],
        [12, "12 hours before the appointment"],
        [24, "24 hours (1 day) before the appointment"],
        [48, "48 hours (2 days) before the appointment"],
        [72, "72 hours (3 days) before the appointment"]
    ]

    const banners = getBanners();
    const bannerOptions = [[null, "No Banner"]].concat(Object.keys(banners).map((s: any) => ([s, banners[s]])).sort((a: any, b: any) => a[1] > b[1] ? 1 : -1));

    const billableOptions = [
        [null, "No Default"], 
        [1, "Must Pay with Order"], 
        [2, "Prefer Pay with Order"], 
        [3, "Prefer to Invoice"], 
        [4, "Must Invoice"], 
        [5, "Must Pay Deposit with Order"]
    ]; 

    const autoTimeOptions = [
        [null, "Do Not Send"], 
        [1, "1 Hour Before Appt"], 
        [2, "2 Hours Before Appt"], 
        [3, "3 Hours Before Appt"], 
        [4, "4 Hours Before Appt"],
        [24, "1 Day Before Appt"],
        [48, "2 Days Before Appt"],
        [72, "3 Days Before Appt"],
        [168, "1 Week Before Appt"],
        [336, "2 Weeks Before Appt"],
        [672, "4 Weeks Before Appt"]
    ];

    const autoTimeOptions2 = [
        [null, "Do Not Send"],
        [1, "1 Hour Before Appt"],
        [2, "2 Hours Before Appt"],
        [3, "3 Hours Before Appt"],
        [4, "4 Hours Before Appt"],
        [24, "1 Day Before Appt"],
        [26, "26 Hours Before Appt"],
        [36, "36 Hours Before Appt (not recommended)"],
        [48, "2 Days Before Appt"],
        [72, "3 Days Before Appt"],
        [168, "1 Week Before Appt"],
        [336, "2 Weeks Before Appt"],
        [672, "4 Weeks Before Appt"]
    ];

    const abandonedCartEmailAutoTimes = [
        [null, "Do Not Send"],
        [15, "15 Minutes after abandonment"],
        [30, "30 Minutes after abandonment"],
        [45, "45 Minutes after abandonment"],
        [60, "1 Hour after abandonment"],
        [120, "2 Hours after abandonment"]
    ];
    
    const reminderTextOptions = [
        "This is an automated reminder about your appointment at [Time] on [Date] with [TeamMember] at [Address]",
        "This is an automated reminder about your appointment at [Time] on [Date] with [TeamMember] at [Address]. Please remember to remove all of your vehicles from the driveway and/or curbside prior to [TeamMember]'s arrival.",
        "This is an automated reminder about your appointment at [Time] on [Date] with [TeamMember] at [Address]. Cancellations within 24 hours may be subject to a fee.",
        "This is an automated reminder about your appointment at [Time] on [Date] with [TeamMember] at [Address]. Please arrive early to open blinds, turn on lights, and move any furniture as necessary."
    ];
        
    const completionTextOptions = [
        "The media for [Address] is ready to access. Click the following link to access your media. [DownloadUrl]",
        "Great News! Your media for [Address] is ready to access. Click the following link to access your media. [DownloadUrl]",
        "Your pics for [Address] are ready!  Please check your email or log in. Thank you for your business."
    ];

    const reviewAutoTimesOptions = [
        [null, "Do Not Send"],
        [12, "12 Hours"],
        [24, "1 Day"],
        [48, "2 Days"],
        [72, "3 Days"],
        [96, "4 Days"],
        [120, "5 Days"],
        [144, "6 Days"],
        [168, "7 Days"]
    ];

    const reviewTextOptions = [
        "Thank you for choosing us for your recent photoshoot on [Address]. If your experience was amazing, could you kindly take a moment to share a review? [ReviewUrl] We'd love to hear your thoughts! - [CompanyName]",
        "Thank you for trusting [CompanyName] with your recent photoshoot on [Address]. If we captured the project perfectly, we'd appreciate a review from you. Thanks for your support! [ReviewUrl]",
        "Hi [ClientFirstName], we hope your recent photoshoot with [CompanyName] exceeded your expectations. Please kindly share your experience in a review. Your feedback means a lot to us! [ReviewUrl]"
    ];    

    const config = [
        {
            title: "Platform Settings",
            icon: <Settings01Icon variant="solid" size={20} />,
            state: [platformSettings, setPlatformSettings],
            items: [
                {
                    type: "text",
                    name: "mlsDownloadSize",
                    label: "MLS Image Download - Default Size",
                    maxLength: 50,
                },
                {
                    type: "select",
                    name: "reschedule",
                    label: "Client Appointment Rescheduling",
                    options: rescheduleOptions
                },
                {
                    type: "keyring",
                    key: "tbdclients",
                    label: "Allow clients to reschedule TBD/unscheduled appt",
                    description: "When an order is not scheduled, allow clients to schedule online from their dashboard."
                },
                {
                    type: "keyring",
                    key: "reschedulephotogchange",
                    label: "Allow all team members during reschedule",
                    description: "When a client is rescheduling an appointment, allow them to view all available photographers or team mode appointment times."
                },
                {
                    type: "keyring",
                    key: "reschedulenocancel",
                    label: "Disable \"cancel\" option during reschedule",
                    description: "When a client is rescheduling an appointment, don't give them the option to cancel the appointment."
                },
                {
                    type: "element",
                    label: "Marketing Kit Page Design:",
                    element: 
                        <Box display="flex" alignItems="center" mt={2}>
                            <Typography variant="body1" fontWeight="bold" flex={1}>{T("Marketing Kit Page Design:")}</Typography>
                            <Button variant="contained" color="primary" endIcon={<ArrowRight01Icon size={20} />} onClick={() => { setMarketingKitPanel(true); }}>{T("Modify")}</Button>
                            <Drawer anchor="right" open={marketingKitPanel} onClose={() => { setMarketingKitPanel(false); }}>
                                <MarketingKitPageDesign onClose={() => { setMarketingKitPanel(false); }} />
                            </Drawer>
                        </Box>,
                },
                {
                    type: "element",
                    label: "Enabled Social Media (click to disable)",
                    element:
                        <Box mt={2}>
                            <Box display="flex">
                                <Typography variant="body1" fontWeight="bold">{T("Enabled Social Media")}</Typography>
                                <Typography variant="body1" ml={1}>{T("(click to disable)")}</Typography>
                            </Box>
                            <Box display="flex" alignItems="center" flexWrap="wrap" sx={{ gap: 1.75, p: 1.625, backgroundColor: "rgba(124, 124, 124, 0.04)", borderRadius: 1, border: "0.5px solid rgba(202, 202, 202, 0.57)" }}>
                                <Button 
                                    variant="outlined" 
                                    sx={{ colorjustifyContent: 'flex-start', width: "8rem", ...(socialDisable.indexOf('fb') === -1) ? { color: "#316ff6", borderColor: "#316ff6", background: 'var(--background-default, #fff)' } : { color: "#b6b6b6", borderColor: "#aaa", background: '#ccc7' } }} 
                                    startIcon={<FontAwesomeIcon icon={faFacebookSquare} style={{ fontSize: "1.75rem" }} />} 
                                    onClick={() => { socialToggle('fb'); }}>Facebook</Button>
                                <Button 
                                    variant="outlined" 
                                    sx={{ justifyContent: 'flex-start', width: "8rem", ...(socialDisable.indexOf('tw') === -1) ? { color: "#000", borderColor: "#000", background: 'var(--background-default, #fff)' } : { color: "#b6b6b6", borderColor: "#aaa", background: '#ccc7' } }} 
                                    startIcon={<FontAwesomeIcon icon={faSquareXTwitter} style={{ fontSize: "1.75rem" }} />} 
                                    onClick={() => { socialToggle('tw'); }}>X</Button>
                                <Button 
                                    variant="outlined" 
                                    sx={{ justifyContent: 'flex-start', width: "8rem", ...(socialDisable.indexOf('li') === -1) ? { color: "#0A66C2", borderColor: "#0A66C2 ", background: 'var(--background-default, #fff)' } : { color: "#b6b6b6", borderColor: "#aaa", background: '#ccc7' } }} 
                                    startIcon={<FontAwesomeIcon icon={faLinkedin} style={{ fontSize: "1.75rem" }} />} 
                                    onClick={() => { socialToggle('li'); }}>LinkedIn</Button>
                                <Button 
                                    variant="outlined" 
                                    sx={{ justifyContent: 'flex-start', width: "8rem", ...(socialDisable.indexOf('pi') === -1) ? { color: "#E60023", borderColor: "#E60023", background: 'var(--background-default, #fff)' } : { color: "#b6b6b6", borderColor: "#aaa", background: '#ccc7' } }} 
                                    startIcon={<FontAwesomeIcon icon={faPinterestSquare} style={{ fontSize: "1.75rem" }} />} 
                                    onClick={() => { socialToggle('pi'); }}>Pinterest
                                </Button>
                            </Box>
                        </Box>
                }
            ]
        },
        {
            title: "New Site Defaults",
            icon: <SidebarTopIcon variant="solid" size={20} />,
            state: [newSiteDefaults, setNewSiteDefaults],
            items: [
                {
                    type: "select",
                    name: "bannerId",
                    label: "Special Marketing Banner upon Activation",
                    options: bannerOptions
                },
                {
                    type: "keyring",
                    key: "media-nonames",
                    label: "Hide Photo Names",
                    description: "By default, new sites will have their photo names hidden from public display. This can be re-enabled on the Site Media page on any Site.",
                },
                {
                    type: "keyring",
                    key: "media-mute",
                    label: "Mute New Video Clips",
                    description: "When new video clips are uploaded, mute the audio by default.",
                },
                {
                    type: "keyring",
                    key: "site-noschool",
                    label: "Disable School Links",
                    description: "New sites will not have a Schools link displayed unless one is enabled.",
                },
                {
                    type: "keyring",
                    key: "site-nolocal",
                    label: "Disable Local Demographics Links",
                    description: "New sites will not have a Local Demographics link displayed unless one is enabled.",
                },
                {
                    type: "keyring",
                    key: "site-music",
                    label: "Include Background Music",
                    description: "New sites will automatically get a background music track.",
                },
                {
                    type: "keyring",
                    key: "site-wtrsubcribe",
                    label: "Subscribe Clients to Weekly Traffic Report",
                    description: "New sites will automatically assign client to the weekly traffic report.",
                }
            ]
        },
        {
            title: "Automated Communication",
            icon: <MessageMultiple01Icon variant="solid" size={20} />,
            state: [automatedCommunication, setAutomatedCommunication],
            items: [
                {
                    type: "heading",
                    label: "Confirmation Email",
                    sx: { mb: 3 },
                    items: [
                        {
                            type: "keyring",
                            key: "instaconfirmemail",
                            label: "Send a Confirmation Email when a new order is placed",
                            description: "Must be using a Shopping Cart with the Smart Calendar (date and time selection with Google Calendar integration)",
                            underline: false
                        },
                        {
                            type: "box",
                            sx: { display: "flex", alignItems: "center", flexWrap: "wrap", gap: 2 },
                            items: [
                                {
                                    boxSx: { flex: 1 },
                                    type: "select",
                                    name: "confirmationAuto",
                                    label: "Follow-Up Confirmation Email",
                                    options: autoTimeOptions,
                                },
                                {
                                    type: "element",
                                    label: "Edit Confirmation Email",
                                    element:
                                        <Button component={Link} to="/ux/Business/Settings/EmailMessages" variant="outlined" color="primary" sx={{ mt: 1 }} endIcon={<MailEdit02Icon variant="solid" size={20} />}>{T("Edit Confirmation Email")}</Button>
                                }
                            ]
                        }
                    ]
                },
                {
                    type: "heading",
                    label: "Reminder Text Message",
                    sx: { mb: 5 },
                    items: [
                        {
                            type: "select",
                            name: "reminderAuto",
                            label: "Reminder Text Message",
                            options: autoTimeOptions2,
                        },
                        {
                            type: "box",
                            sx: { display: "flex", alignItems: "center", flexWrap: "wrap", gap: 2, mb: 2 },
                            items: [
                                {
                                    boxSx: { flexBasis: "300px !important", flex: 1, height: "5.2rem", mb: -2, width: "300px" },
                                    type: "select",
                                    name: "reminderText",
                                    label: "Reminder Text Messsage Template",
                                    options: reminderTextOptions,
                                    sx: { position: "absolute" },
                                    className: "TwoLine"
                                },
                                {
                                    boxSx: { flexBasis: "300px !important", flex: 1, height: "5.2rem", mb: -2, width: "300px" },
                                    type: "select",
                                    name: "completionText",
                                    label: "Completion Text Messsage Template",
                                    options: completionTextOptions,
                                    sx: { position: "absolute" },
                                    className: "TwoLine"
                                }
                            ]
                        }
                    ]
                },
                {
                    type: "heading",
                    label: "Abandoned Cart",
                    sx: { mb: 5 },
                    items: [
                        {
                            type: "box",
                            sx: { display: "flex", alignItems: "center", flexWrap: "wrap", gap: 2, mb: 2 },
                            items: [
                                {
                                    boxSx: { flexBasis: "300px !important", flex: 1, height: "3.6rem", mb: -2, width: "300px" },
                                    sx: { position: "absolute" },
                                    type: "select",
                                    name: "abandonedCartEmailAuto",
                                    label: "Abandoned Cart Email",
                                    options: abandonedCartEmailAutoTimes
                                },
                                {
                                    boxSx: { flexBasis: "300px !important", flex: 1, height: "3.6rem", mb: -2, width: "300px" },
                                    sx: { position: "absolute" },
                                    type: "text",
                                    name: "abandonedCartEmail",
                                    label: "Abandoned Cart Email Address",
                                    include: !!(automatedCommunication.data.abandonedCartEmailAuto !== undefined ? automatedCommunication.data.abandonedCartEmailAuto : data?.abandonedCartEmailAuto || data?.abandonedCartEmailAuto)
                                }
                            ]
                        }
                    ]
                },
                {
                    type: "heading",
                    label: "Leave a Review Email",
                    items: [
                        {
                            type: "box",
                            sx: { display: "flex", alignItems: "center", gap: 2 },
                            items: [
                                {
                                    boxSx: { flex: 1, flexBasis: "300px !important" },
                                    type: "select",
                                    name: "reviewEmailAuto",
                                    label: "Leave a Review Email",
                                    options: autoTimeOptions,
                                },
                                {
                                    type: "element",
                                    label: "Edit Leave a Review Email",
                                    element:
                                        <Button component={Link} to="/ux/Business/Settings/EmailMessages" variant="outlined" color="primary" sx={{ mt: 1 }} endIcon={<MailEdit02Icon variant="solid" size={20} />}>{T("Edit Leave a Review Email")}</Button>
                                }
                            ]
                        },
                        {
                            type: "select",
                            name: "reviewTextAuto",
                            label: "Leave a Review Text Message",
                            options: reviewAutoTimesOptions,
                        },
                        {
                            type: "select",
                            name: "reviewTextMessage",
                            label: "Leave a Review Text Message Template",
                            options: reviewTextOptions,
                            className: "TwoLine"
                        },
                        {
                            type: "text",
                            name: "preferredReviewUrl",
                            label: "Preferred Review URL",
                            validate: "url"
                        }
                    ]
                }
            ]
        },
        {
            title: "New Client Defaults",
            icon: <WorkUpdateIcon variant="solid" size={20} />,
            state: [newClientDefaults, setNewClientDefaults],
            items: [
                {
                    type: "box",
                    sx: { display: "flex", flexWrap: "wrap", gap: 2 },
                    items: [
                        {
                            boxSx: { flex: 2 },
                            type: "select",
                            name: "billableId",
                            label: "Default Client Billing Option",
                            options: billableOptions
                        },
                        {
                            boxSx: { flex: 1 },
                            type: "select",
                            name: "depositType",
                            label: "Deposit Type",
                            maxLength: 50,
                            options: [[1, "Percentage of Order Total"], [2, "Fixed Dollar Amount"]],
                            defaultValue: 1,
                            onChange: (e: any, v: number) => {
                                setDepositType(v || 1);
                            },
                            include: (billableId > 4)
                        },
                        {
                            boxSx: { flex: 1, display: (depositType !== 1) ? "none" : "flex" },
                            type: "text",
                            name: "depositPercent",
                            label: "Deposit Percentage",
                            maxLength: 5,
                            include: (billableId > 4)
                        },
                        {
                            boxSx: { flex: 1, display: (depositType !== 2) ? "none" : "flex" },
                            type: "text",
                            name: "depositAmount",
                            label: "Deposit Amount",
                            maxLength: 5,
                            include: (billableId > 4)
                        }
                    ]
                },
                {
                    type: "keyring",
                    field: "defaultUserKeyRing",
                    key: "site-photos", 
                    label: "Allow Clients to Edit Site Photos", 
                    description: "By default, allow photos to be uploaded and deleted for the client's sites by the client or the client's assistants.",
                    rightElement: <ButtonApi 
                        start={{ variant: "outlined", color: "primary", label: T("Apply to all accounts") }}
                        success={{ variant: "contained", color: "success", label: T("Applied"), startIcon: <Tick02Icon variant="solid" size={20} /> }}
                        run={(success: any, fail: any, reset: any) => { applyToAll("site-photos", success, fail, reset); }}
                    />
                },
                {
                    type: "keyring",
                    field: "defaultUserKeyRing",
                    key: "site-attachments", 
                    label: "Allow Clients to Edit Site Attachments", 
                    description: "By default, allow attachments to be uploaded and deleted for the client's sites by the client or the client's assistants.",
                    rightElement: <ButtonApi
                        start={{ variant: "outlined", color: "primary", label: T("Apply to all accounts") }}
                        success={{ variant: "contained", color: "success", label: T("Applied"), startIcon: <Tick02Icon variant="solid" size={20} /> }}
                        run={(success: any, fail: any, reset: any) => { applyToAll("site-attachments", success, fail, reset); }}
                    />
                },
                {
                    type: "keyring",
                    field: "defaultUserKeyRing",
                    key: "site-embeds", 
                    label: "Allow Clients to Edit Site Embedded Media", 
                    description: "By default, allow embedded media to be uploaded and deleted for the client's sites by the client or the client's assistants.",
                    rightElement: <ButtonApi 
                        start={{ variant: "outlined", color: "primary", label: T("Apply to all accounts") }}
                        success={{ variant: "contained", color: "success", label: T("Applied"), startIcon: <Tick02Icon variant="solid" size={20} /> }}
                        run={(success: any, fail: any, reset: any) => { applyToAll("site-embeds", success, fail, reset); }}
                    />
                },
                {
                    type: "keyring",
                    field: "defaultUserKeyRing",
                    key: "site-noyoutube", 
                    label: "Opt Client Out of YouTube Uploading", 
                    description: "By default, do not upload any social media videos to any YouTube channels.",
                    rightElement: <ButtonApi
                        start={{ variant: "outlined", color: "primary", label: T("Apply to all accounts") }}
                        success={{ variant: "contained", color: "success", label: T("Applied"), startIcon: <Tick02Icon variant="solid" size={20} /> }}
                        run={(success: any, fail: any, reset: any) => { applyToAll("site-noyoutube", success, fail, reset); }}
                    />
                },
                {
                    type: "keyring",
                    field: "defaultUserKeyRing",
                    key: "site-mustpayoff", 
                    label: "Allow Clients to Access Photos Without Payment", 
                    description: "By default, when a new order is placed, set the \"Require payment to access photos\" option to off.",
                    rightElement: <ButtonApi
                        start={{ variant: "outlined", color: "primary", label: T("Apply to all accounts") }}
                        success={{ variant: "contained", color: "success", label: T("Applied"), startIcon: <Tick02Icon variant="solid" size={20} /> }}
                        run={(success: any, fail: any, reset: any) => { applyToAll("site-mustpayoff", success, fail, reset); }}
                    />
                }
            ]
        },
        {
            title: "Billing Options",
            icon: <Wallet01Icon variant="solid" size={20} />,
            state: [billingOptions, setBillingOptions],
            items: [
                {
                    type: "box",
                    sx: { display: "flex", flexWrap: "wrap", gap: 2 },
                    items: [
                        {
                            boxSx: { flex: 1 },
                            type: "select",
                            name: "referralMode",
                            label: "Client Referrals",
                            options: [[1, "No Referral Program"], [2, "Referral Program"]]
                        },
                        {
                            boxSx: { flex: 1 },
                            type: "text",
                            name: "referralAmount",
                            label: "Client Referral Amount",
                            maxLength: 10,
                            include: ((billingOptions.data?.referralMode || data?.referralMode) === 2)
                        }
                    ]
                },
                {
                    type: "keyring",
                    key: "allowtips", 
                    label: "Allow Tips when Order Balances are Being Paid", 
                    description: "When a balance is being paid, allow the client to add a tip to the order.",
                    rightElement: <Button variant="outlined" color="primary" sx={{ ml: 2, textTransform: 'none' }} href="https://help.hdphotohub.com/support/solutions/articles/69000824113-accepting-tips" target="_blank">{T("Learn More")}</Button>,
                    underline: false
                },
                {
                    type: "box",
                    sx: { display: "flex", flexWrap: "wrap", gap: 2 },
                    items: [
                        {
                            boxSx: { flex: 1 },
                            type: "select",
                            name: "tipPayoutMode",
                            label: "Tip Payout Mode",
                            options: [[0, "Business"], [1, "By Product"]]
                        },
                        {
                            boxSx: { flex: 1 },
                            type: "text",
                            name: "tipAllocation",
                            label: "Business Allocation %",
                            maxLength: 3
                        }
                    ]
                },
                {
                    type: "box",
                    sx: { display: "flex", flexWrap: "wrap", gap: 2 },
                    items: [
                        {
                            boxSx: { flex: 1 },
                            type: "select",
                            name: "tipType",
                            label: "Suggested Tip",
                            options: [['a', "By Amount"], ['p', "By Percentage"]]
                        },
                        {
                            boxSx: { flex: 1 },
                            type: "text",
                            name: "tip1",
                            label: "Low Tip",
                            maxLength: 3
                        },
                        {
                            boxSx: { flex: 1 },
                            type: "text",
                            name: "tip2",
                            label: "Mid Tip",
                            maxLength: 3
                        },
                        {
                            boxSx: { flex: 1 },
                            type: "text",
                            name: "tip3",
                            label: "High Tip",
                            maxLength: 3
                        }
                    ]
                },
                {
                    type: "keyring",
                    key: "email-tmtip", 
                    label: "Email Team Member(s) when Client Leaves Tip", 
                    description: "When checked, the Team Members on the order that are receiving part of the tip will receive the Client Tip Notification email when a tip has been left on the order."
                },
                {
                    type: "keyring",
                    key: "email-botip", 
                    label: "Email Business Owner when Client leaves Tip", 
                    description: "When checked, the Photography Business Owner will receive the Client Tip Notification email when a tip has been left on an order."
                },
                {
                    type: "keyring",
                    key: "paywhencomplete", 
                    label: "Clients Pay When The Order Is Complete", 
                    description: "Don't include a PAY NOW button on unpaid invoices until the order is complete."
                },
                {
                    type: "keyring",
                    key: "cckeepcard", 
                    label: "Require client to keep Credit/Debit Card on File", 
                    description: "When a client places an order or pays a balance, always store card to the client's account."
                },
                {
                    type: "keyring",
                    key: "skipbillingaddress", 
                    label: "Do not ask clients for their billing address during an order", 
                    description: "Use the property address for the tax rate look up if the client does not have a billing address in their account."
                },
                {
                    type: "keyring",
                    key: "payoutautoupdate", 
                    label: "Update Pay Out When Product Is Transferred", 
                    description: "When a product is tranferred from one photographer to another, and their default payout amounts are different, automaticlaly update the pay amount."
                },
                {
                    type: "keyring",
                    key: "travfeereschedule", 
                    label: "Allow Travel Fee Changes on Reschedule", 
                    description: "When a product is being rescheduled, allow travel fee changes to apply to the order."
                },
                {
                    type: "keyring",
                    key: "invoiceswapordername", 
                    label: "Change \"Order #\" to \"Invoice #\" on Invoice", 
                    description: "Show Invoice # instead of Order # for Order Invoices at the top of the Invoice."
                },
                {
                    type: "keyring",
                    key: "email-noinvoice", 
                    label: "Disable Invoice Email", 
                    description: "Don't send an invoice email to clients when an order is placed."
                }
            ]
        },
        {
            title: "Platform Features",
            icon: <MoreHorizontalSquare02Icon variant="solid" size={20} />,
            state: [platformFeatures, setPlatformFeatures],
            items: [
                {
                    type: "keyring",
                    key: "email-bcc", 
                    label: "Email BCC to Business Owner for all System Emails", 
                    description: "For all system emails sent to clients, send a BCC email to the Photography Business Owner."
                },
                {
                    type: "keyring",
                    key: "email-excludeneworder", 
                    label: "Opt out of all Team Member New Order Emails", 
                    description: "For all New Order Emails sent to Team Members, don't CC the Photography Business Owner."
                },
                {
                    type: "keyring",
                    key: "markets", 
                    label: "Enable Market Areas", 
                    description: "Enable additional configuration options for large teams that operate teams in several cities."
                },
                {
                    type: "keyring",
                    key: "balanceallphotos", 
                    label: "Allow Clients to Preview All Photos", 
                    description: "When a client has a balance due, allow them to see a small preview of all of the photos prior to paying for the order."
                }
            ]
        },
        {
            title: "Team Member Settings",
            icon: <UserCheck01Icon variant="solid" size={20} />,
            state: [teamMemberSettings, setTeamMemberSettings],
            items: [
                {
                    type: "keyring",
                    key: "dash-tmsimple", 
                    label: "Simple Mode for Team Members", 
                    description: "Don't allow Team Members to create new Sites, Clients or Groups, and hide top-level traffic stats and Help Center Videos on the dashboard, and invoice related functionality."
                },
                {
                    type: "keyring",
                    key: "limitsitesteam", 
                    label: "Limit Sites for Team Members", 
                    description: "Only allow Team Members to search for Sites for which they have been assigned tasks."
                },
                {
                    type: "keyring",
                    key: "site-disablecreditcostdisplay", 
                    label: "Disable Credit/Price Display on Activation", 
                    description: "Don't display the price and credit cost on the Site Activation page for team members (rare)."
                },
                {
                    type: "keyring",
                    key: "order-confirmationemail", 
                    label: "Display Team Member Photo on the Appt Confirmation", 
                    description: "Display the Team Member photo on the confirmation email."
                },
                {
                    type: "keyring",
                    key: "client-disablewho", 
                    label: "Don't show Team Member on Email and Client Dashboard",
                    description: "Don't display the \"Who\" lines in appointment confirmation emails and the \"Provider\" on the client dashboard (rare)."
                },
                {
                    type: "keyring",
                    key: "order-disabletaskemails", 
                    label: "Disable Task Assignment/Reschedule Emails",
                    description: "Disable the Task Assignment and Reschedule Emails for Team Members."
                },
                {
                    type: "keyring",
                    key: "order-doneself", 
                    label: "Only Let Team Members Complete Their Own Tasks",
                    description: "Don't allow Team Members to mark any tasks as complete if they are assigned to another Team Member."
                },
                {
                    type: "keyring",
                    key: "servicearea-view", 
                    label: "Enable Service Area Viewing",
                    description: "Allow Team Members to view their existing Service Areas only."
                },
                {
                    type: "keyring",
                    key: "servicearea-basic", 
                    label: "Enable Service Area Basics",
                    description: "Allow Team Members to view and edit the boundary of their existing Service Areas only."
                },
                {
                    type: "keyring",
                    key: "servicearea-admin", 
                    label: "Enable Service Area Admin",
                    description: "Allow Team Members to create new/delete Service Areas and edit their Travel Fees (Must also have 'Enable Service Area Basics' enabled)."
                },
            ]
        },
        {
            title: "Integration Settings",
            icon: <SlidersHorizontalIcon variant="solid" size={20} />,
            state: [integrationSettings, setIntegrationSettings],
            items: [
                {
                    type: "keyring",
                    key: "gcal-nonotes",
                    label: "Google Calendar: Disable All Notes in Appointments", 
                    description: "Do not include the order notes in the details of the appointments that are automatically made in Google Calendar."
                },
                {
                    type: "keyring",
                    key: "gcal-nosysnotes",
                    label: "Google Calendar: Disable System Notes in Appointments", 
                    description: "Do not include the system order notes in the details of the appointments that are automatically made in Google Calendar."
                },
                {
                    type: "keyring",
                    key: "gcal-nolink",
                    label: "Google Calendar: Disable Link in Appointments", 
                    description: "Do not include a link to edit the order in the details of the appointments that are automatically made in Google Calendar."
                },
                {
                    type: "keyring",
                    key: "iguide-altsitelinks",
                    label: "iGuide: Provide MLS and Social links", 
                    description: "When an iGuide is on a Site, provide the client with separate Property Site links for the MLS Property Site (iGuide link) and the Social Media Property Site (HDPhotoHub Property Site)."                    
                },
                {
                    type: "keyring",
                    key: "xpressdocs-suppress",
                    label: "Xpressdocs: Suppress plugin", 
                    description: "Do not allow integration with Xpressdocs."
                }
            ]
        },
        {
            title: "MLS Compliance",
            icon: <House04Icon variant="solid" size={20} />,
            state: [mlsCompliance, setMlsCompliance],
            items: [
                {
                    type: "keyring",
                    key: "mlsbrokerbranding", 
                    label: "Display Broker Name with Agent on Property Sites", 
                    description: "Include the Group name and phone number with the Client contact info (high MLS compliance)."
                },
                {
                    type: "keyring",
                    key: "mlsbrokeraddress", 
                    label: "Display Broker Address on Property Sites", 
                    description: "Include the Group address with the Client contact info (high MLS compliance)."
                },
                {
                    type: "keyring",
                    key: "mlsnolinks", 
                    label: "Disable External Links in Unbranded Property Sites", 
                    description: "Removes Share links, School links and Area Info links in the Unbranded Property Sites (high MLS compliance)."
                },
                {
                    type: "keyring",
                    key: "mlsnoaddressidx", 
                    label: "Disable the Address for Unbranded Property Sites", 
                    description: "Removes the property address and map from the property sites, and removes the shortcut URL from the unbranded links."
                },
                {
                    type: "keyring",
                    key: "nounbrandedattribution", 
                    label: "Disable Attribution on Unbranded Sites", 
                    description: "When a site it being displayed in the Unbranded Mode, don't show the attribution."
                },
                {
                    type: "keyring",
                    key: "notermsofuse", 
                    label: "Disable the Terms of Use in Property Site footers", 
                    description: "Do not include the Terms of Use legal disclaimer on property sites, and assume all legal responsibility and consequences directly."
                }
            ]
        },
        {
            title: "Disabled Features",
            icon: <ToggleOffIcon variant="solid" size={20} />,
            state: [disabledFeatures, setDisabledFeatures],
            items: [
                {
                    type: "keyring",
                    key: "site-noconfownerpreview", 
                    label: "Disable Property Owner Preview on Completion Email", 
                    description: "Don't include the Property Owner Preview link in the completion email when the order is completely paid.  That section is always disabled for unpaid orders unless the payment is not required to access the photos."
                },
                {
                    type: "keyring",
                    key: "site-alwaysconfownerpreview",
                    label: "Disable Hiding the Property Owner Preview on Completion Email", 
                    description: "Always include the Property Owner Preview link in the completion email, regardless of order payment status."
                },
                {
                    type: "keyring",
                    key: "site-lockfriendly", 
                    label: "Disable Edits to Friendly URL by Clients", 
                    description: "Don't allow clients to edit the Friendly URL field of a property site."
                },
                {
                    type: "keyring",
                    key: "site-noshorturl", 
                    label: "Disable Short URL", 
                    description: "Don't provide your clients with a short URL using the \"hd.pics\" domain."
                },
                {
                    type: "keyring",
                    key: "site-disableprintmaterial", 
                    label: "Disable Print Materials", 
                    description: "Don't provide your clients with a print materials section on their Sites."
                },
                {
                    type: "keyring",
                    key: "site-disablesocialtiles", 
                    label: "Disable Social Tiles", 
                    description: "Don't provide your clients with Social Tiles on their Sites."
                },
                {
                    type: "keyring",
                    key: "site-disablesocialvideos", 
                    label: "Disable Social Videos", 
                    description: "Don't provide your clients with Social Videos on their Sites."
                },
                {
                    type: "keyring",
                    key: "site-disableseasonal", 
                    label: "Disable Seasonal Marketing Designs", 
                    description: "Don't offer reels that are seasonal in their design style."
                },
                {
                    type: "keyring",
                    key: "site-disablesitevideos", 
                    label: "Disable Slideshows", 
                    description: "Don't provide your clients with Slideshows on their Sites."
                },
                {
                    type: "keyring",
                    key: "site-disablepropertysite", 
                    label: "Disable Property Websites", 
                    description: "Don't offer Property Websites to clients on their Sites (rare)."
                },
                {
                    type: "keyring",
                    key: "site-disableleadcapture", 
                    label: "Disable Lead Capture", 
                    description: "Don't provide your clients with Lead Capture on their Sites (rare)."
                },
                {
                    type: "keyring",
                    key: "site-disablestandarddesigns", 
                    label: "Disable Standard Site Designs", 
                    description: "Don't offer standard Site Designs, instead only offer your custom Site Designs (rare)."
                },
                {
                    type: "keyring",
                    key: "site-disabledownload", 
                    label: "Disable Photo Download", 
                    description: "Don't provide your clients with a way to download their photos (rare)."
                },
                {
                    type: "keyring",
                    key: "user-disableshootdate",
                    label: "Disable Photo Shoots on Client Dashboard", 
                    description: "Don't display future photo shoots on the client's dashboard until a confirmation email has been sent (rare)."
                },
                {
                    type: "keyring",
                    key: "user-disableinvite", 
                    label: "Disable Client Edit Invite", 
                    description: "On the Completion Email, don't invite clients to log in and edit their property sites (rare)."
                },
                {
                    type: "keyring",
                    key: "support-disablehd", 
                    label: "Disable Default Support Page Content", 
                    description: "Don't display the default content/contact info on the Support page. Custom content can be added using HTML on Business > Business Details."
                },
                {
                    type: "keyring",
                    key: "footer-disablehd", 
                    label: "Disable Copyright in Footer", 
                    description: "When clients log into the website, disable the copyright displayed in the page footer."
                }
            ]
        },
        {
            title: "Other",
            icon: <NoteEditIcon variant="solid" size={20} />,
            state: [other, setOther],
            items: [
                {
                    type: "keyring",
                    key: "hdyoutube", 
                    label: "Upload to HDPhotoHub's YouTube Channel", 
                    description: "Upload Branded and Unbranded videos to HDPhotoHub's YouTube Channel if a business channel is not configured."
                },
                {
                    type: "keyring",
                    key: "noexample", 
                    label: "Disable Property Site Sharing", 
                    description: "Do not show any of my Property Sites as examples on the HDPhotoHub home page."
                }
            ]
        },
        {
            heading: "Admin Settings",
            include: (!search)
        },
        {
            title: "Push Notifications",
            icon: <Notification01Icon variant="solid" size={20} />,
            className: "admin-area",
            state: [pushNotifications, setPushNotifications],
            items: [
                {
                    type: "keyring",
                    key: "push-notifications", 
                    label: "Enable Push Notifications Feature - TODO: Look up if brand's account supports this", 
                    description: "Send push notifications to mobile app users."
                }
            ]
        },
        {
            title: "Optional Modules",
            icon: <CellsIcon variant="solid" size={20} />,
            className: "admin-area",
            state: [optionalModules, setOptionalModules],
            items: [
                {
                    type: "keyring",
                    key: "orderfinalize", 
                    label: "Order Date Based on Finalization", 
                    description: "Provide a UI to \"finalize\" orders.  Invoices are draft until then, use the Finalize Date as the Invoice Date and don't import into QuickBooks until they are finalized.",
                    x_TODO_if_Brand_is_not_Parent: " (<a href=\"#\" onclick=\"finalizeDates(); return false;\" style=\"font-size: 0.8em;\">Finalize Order Dates</a>)"
                },
                {
                    type: "keyring",
                    key: "productfees", 
                    label: "Enable Product Fees", 
                    description: "Enable additional configuration options for complex fee calculation."
                },
                {
                    type: "keyring",
                    key: "groupserviceareas", 
                    label: "Enable Group Service Areas", 
                    description: "Enable group configuration options for travel fee overrides."
                },
                {
                    type: "keyring",
                    key: "mobileapp", 
                    label: "Mobile App Config Page", 
                    description: "Enable early access to the Mobile App configuration page."
                },
                {
                    type: "keyring",
                    key: "nostream", 
                    label: "Use HDPhotoHub Vimeo Account for Videos", 
                    description: "Use the old HDPhotoHub Vimeo Account for future video uploads for this account (only applies if Brand-scope Vimeo App Integration isn't enabled)."
                },
                {
                    type: "keyring",
                    key: "monthlybilling", 
                    label: "Monthly Billing (Enterprise Accounts Only)",
                    description: "Create an invoice for each month's credit use on this account (including child Brands that get billed to the parent).  Approval from Herb or Ian is required to enable this feature."
                },
                {
                    type: "keyring",
                    key: "credit-billable", 
                    label: "Allow Negative Credits", 
                    description: "Allow Negative Credits for this Brand."
                },
                {
                    type: "keyring",
                    key: "delivery-logistics", 
                    label: "Allow Delivery Logistics", 
                    description: "Allow the Delivery Logistics fields to be enabled on Sites that don't have an order."
                }
            ]    
        },
        {
            title: "Major Brand-Specific",
            icon: <ConnectIcon variant="solid" size={20} />,
            className: "admin-area",
            state: [majorBrandSpecific, setMajorBrandSpecific],
            items: [
                {
                    type: "keyring",
                    key: "vhtbrand", 
                    label: "VHT Brand", 
                    description: "Order form checks with the VHT API to determine product pricing."
                },
                {
                    type: "keyring",
                    key: "tfbrand", 
                    label: "TourFactory Brand", 
                    description: "Order form checks with the TF API to determine applicable price tiers."
                },
                {
                    type: "keyring",
                    key: "tfisolve", 
                    label: "TourFactory iSolve Payroll Export", 
                    description: "iSolve Export option on pay team page."
                },
                {
                    type: "keyring",
                    key: "tftourbuttons", 
                    label: "TourFactory Tour Button Section on Activation Page", 
                    description: "Add a TourFactory Tour section on the Activation page for editing and updating the Tour status."
                },
                {
                    type: "keyring",
                    key: "child-uniqueemails", 
                    label: "Unique Emails for Child Brand Clients", 
                    description: "Require Unique Email Addresses for all Child Brand Clients."
                }
            ]
        },
        {
            title: "Restricted Areas",
            icon: <PrisonGuardIcon variant="solid" size={20} />,
            className: "admin-area",
            state: [additions, setAdditions],
            items: [
                {
                    type: "keyring",
                    key: "restricted-fees", 
                    label: "Product Fee Edits", 
                    description: "Restrict Product Fees to viewing but not editing, unless additional elevated access is granted for a particular user."
                },
                {
                    type: "keyring",
                    key: "restricted-childfees", 
                    label: "Child Business Product Fee Edits", 
                    description: "Restrict Product Fees in Child Businesses to viewing but not editing, unless additional elevated access is granted for a particular user."
                },
                {
                    type: "keyring",
                    key: "restricted-childsettings", 
                    label: "Child Business Default Settings", 
                    description: "Restrict Default Settings in Child Businesses to viewing but not editing, unless additional elevated access is granted for a particular user."
                },
                {
                    type: "keyring",
                    key: "restricted-childdetails", 
                    label: "Child Business Details", 
                    description: "Restrict Business Details in Child Businesses to viewing but not editing, unless additional elevated access is granted for a particular user."
                },
                {
                    type: "keyring",
                    key: "restricted-childsalestax", 
                    label: "Child Business Sales Tax", 
                    description: "Restrict Sales Tax in Child Businesses to viewing but not editing, unless additional elevated access is granted for a particular user."
                }
            ]
        },
        {
            title: "Additions",
            icon: <PlusSignCircleIcon variant="solid" size={20} />,
            className: "admin-area",
            state: [additions, setAdditions],
            items: [
                {
                    type: "keyring",
                    key: "uploaderrorextra",
                    label: "Upload Error Extra Details",
                    description: "Include failed image and put details in a different Slack channel for trouble-shooting of failed uploads."
                },
                {
                    type: "keyring",
                    key: "leadcap2",
                    label: "Second Lead Capture Number",
                    description: "Allow a second Lead Capture Number to be configured for this business."
                },
                {
                    type: "keyring",
                    key: "mlsdomainprefix",
                    label: "MLS Domain Prefix",
                    description: "Add 'mls.' before the unbranded MLS links."
                },
                {
                    type: "keyring",
                    key: "brand-verifyallemails",
                    label: "Show Delivery Optimization for all Verified Emails",
                    description: "Don't hide domains that are detected to be used in other accounts on the Email Messages page."
                },
                {
                    type: "keyring",
                    key: "15min",
                    label: "Time Picker Use 15-Mintute Interval",
                    description: "Have the time picker for photo shoots on the Dashboard and the Site Summary page offer 15 minute increments."
                },
                {
                    type: "keyring",
                    key: "include",
                    label: "Allow HTML Include",
                    description: "Allow open HTML area that gets included on every page."
                },
                {
                    type: "keyring",
                    key: "supportpghtml",
                    label: "Allow Support Page HTML",
                    description: "Allow HTML area to add content to Support page."
                },
                {
                    type: "keyring",
                    key: "copypages",
                    label: "Allow Ability to Copy Business Page(s)", 
                    description: "Allow the Brand Owner to utilize the Copy Business Page tool."
                },
                {
                    type: "keyring",
                    key: "customemail", 
                    label: "Allow Custom Email", 
                    description: "Allow the Brand to customize their email messages (before they have made a purchase)."
                },
                {
                    type: "keyring",
                    key: "metadatafield", 
                    label: "Allow Metadata Field", 
                    description: "This is a generic field that is Brand specific and will be on the Business Details page (ex: TF uses this field for a comma list of zipcodes for TAPs (Brands)) READ ONLY UNLESS USER TYPE 1 OR BO/TM HAS Edit Metadata Permission."
                },
                {
                    type: "keyring",
                    key: "reels",
                    label: "Allow Custom Reels", 
                    description: "Enable Custom Reels to be created from the Custom Designs page."
                },
                {
                    type: "keyring",
                    key: "aiclips", 
                    label: "Allow AI Clips", 
                    description: "Enable AI Clips option on the Marketing Kit page."
                }
            ]
        },
        {
            title: "Subtractions",
            icon: <MinusSignCircleIcon variant="solid" size={20} />,
            className: "admin-area",
            state: [subtractions, setSubtractions],
            items: [
                { 
                    type: "keyring",
                    key: "mlsnocustomdomains",
                    label: "MLS Disable Custom Domains",
                    description: "Don't use custom site domains for unbranded MLS links. (high MLS compliance)"
                },
                { 
                    type: "keyring",
                    key: "nosleep",
                    label: "Don't Allow Media to Sleep",
                    description: "Keep all media for this brand awake, unless manually put to sleep."
                },
                { 
                    type: "keyring",
                    key: "noexpire",
                    label: "Don't Expire Sites",
                    description: "All Sites for this Brand will stay active after expiring."
                },
                { 
                    type: "keyring",
                    key: "nochat",
                    label: "Disable Chat and Userflow",
                    description: "Don't offer the chat box or Userflow widget."
                },
                { 
                    type: "keyring",
                    key: "noinvoicesitelink",
                    label: "Disable Site Link on Invoice",
                    description: "Don't display the Site Link in the Project Details section of the Invoice."
                },
                {
                    type: "keyring",
                    key: "hideproducttags",
                    label: "Hide Product Tags when Editing Products",
                    description: "Don't show the Product Tags when editing Shopping Cart Products."
                }
            ]
        }
    ];

    useLoad(() => {
        Api.get('brand').then((r: any) => {
            if (r.ok) { 
                r.data.referralMode = (r.data.referralAmount) ? 2 : 1;
                setData(r.data); 
                // console.log(r.data);
            }
        });
    });

    const applyToAll = (key: string, success: any, fail: any, reset: any) => {
        console.log('applyToAll', key);
        Api.get('brand/userkeyring/' + key).then((r: any) => {
            var hasKey = (newClientDefaults.data.addKeyRing || '').split(',').indexOf(key) > -1 ? true : (newClientDefaults.data.removeKeyRing || '').split(',').indexOf(key) > -1 ? false : ((data.defaultUserKeyRing || '').split(',').indexOf(key) > -1);
            const updatable = r.data[0][!hasKey ? 'included' : 'missing'];
            console.log('qty check', r.data[0], key, hasKey, updatable);

            if (updatable > 0) {
                dialogs.confirm(T('Are you sure you want to apply this setting to the ' + updatable + (updatable > 1 ? ' clients' : ' client') + ' that don\'t have it?'), { title: T('Apply to All Users') }).then((r: any) => {
                    if (r) {
                        Api.patch('brand/userkeyring/', { key: key, ...(hasKey ? { add: [key] } : { remove: [key] })}).then((r: any) => {
                            if (r.ok) {
                                success();
                            }
                            else {
                                fail(3000);
                            }
                        })
                    }
                    else {
                        reset();
                    }
                });
            }
            else {
                dialogs.alert(T('All clients already have this setting.'));
                reset();
            }          
        });


        
    }

    const socialToggle = (s: string) => {
        setPlatformSettings((prev) => { 
            var socialDisable = (prev.data.socialDisable !== undefined ? prev.data.socialDisable : (data.socialDisable || '')).split(',');
            if (socialDisable.indexOf(s) > -1) { socialDisable.splice(socialDisable.indexOf(s), 1); } else { socialDisable.push(s); }
            const nsd = socialDisable.filter((v: any) => { return v !== ''; }).sort().join(',');
            if (nsd !== data.socialDisable) { 
                prev.data.socialDisable = nsd; 
            } 
            else {
                delete prev.data.socialDisable;
            }
            prev.modified = Object.keys(prev.data).length > 0;
            return {...prev};
        });
    }    

    const saveData = async (updates: any, setUpdates: any, type?: string) => {
        console.log('saveData', updates, type);
        if (type === 'newClientDefaults') {
            let defaultUserKeyRing = (data.defaultUserKeyRing || '').split(',');
            if (updates.addKeyRing) { updates.addKeyRing.split(',').map((v: any) => { if (defaultUserKeyRing.indexOf(v) === -1) { defaultUserKeyRing.push(v); } return v; }); }
            if (updates.removeKeyRing) { updates.removeKeyRing.split(',').map((v: any) => { if (defaultUserKeyRing.indexOf(v) > -1) { defaultUserKeyRing.splice(defaultUserKeyRing.indexOf(v), 1); } return v; }); }
            console.log('defaultUserKeyRing', updates, defaultUserKeyRing)
            updates = { defaultUserKeyRing: defaultUserKeyRing.filter((v: any) => { return v !== ''; }).sort().join(',') }
        }
        if (updates?.depositType) {
            if (updates.depositType === 1) { updates.depositAmount = ''; }
            if (updates.depositType === 2) { updates.depositPercent = ''; }
            delete updates.depositType;
        }
        if (updates.referralMode) { 
            if (updates.referralMode === 1) { updates.referralAmount = null; }
            delete updates.referralMode;
        }
        var tasks = [];
        var actions: { success: (r: any) => void, error: (r: any) => void }[] = [];
        if (updates.addKeyRing || updates.removeKeyRing) {
            var keyRingData = { add: updates.addKeyRing?.split(','), remove: updates.removeKeyRing?.split(',') };
            tasks.push(Api.patch('brand/' + data.brandId + '/keyring', keyRingData));
            actions.push({ 
                success: (r: any) => { 
                    setData((prev: any) => {
                        var keyRing = (prev.keyRing || '').split(',');
                        if (keyRingData.add) { keyRing = keyRing.concat(keyRingData.add); }
                        if (keyRingData.remove) { keyRing = keyRing.filter((v: any) => { return keyRingData.remove.indexOf(v) === -1; }); }
                        const newKeyRing = keyRing.join(',');
                        if (prev.keyRing === newKeyRing) { return prev;}
                        return {...prev, keyRing: newKeyRing};
                    });
                }, 
                error: () => {} 
            });
            delete updates.addKeyRing;
            delete updates.removeKeyRing;
        }

        if(Object.keys(updates).length) {
            tasks.push(Api.patch('brand/' + data.brandId, updates));
            actions.push({ 
                success: () => { setData((prev: any) => ({...prev, ...updates})); }, 
                error: (r: any) => { errorData(r, setUpdates); } 
            });
        }

        if (tasks.length) {            
            var r = await Promise.all(tasks);
            var anyFail = false;
            r.forEach((r: any, i: number) => { 
                if (r.ok) {
                    actions[i].success(r);
                }
                else {
                    actions[i].error(r);
                    anyFail = true;
                }
            });
            if (!anyFail) { 
                snack(T('Update saved.'), { variant: 'success' });
                setUpdates((prev: any) => {
                    return emptyFormData(prev.type);
                });
            }
            else {
                snack(T('Unable to update the business details'), { variant: 'error' });
            }
        }
    }

    const renderItems = (items: any, state: any, ) => {
        return items.map((item: any, j: number) => {
            switch (item.type) {
                case "box":
                    return (
                        <Box key={j} sx={item.sx}>
                            {item.items && item.items.map((item: any, k: number) => (<Box key={k} sx={item.boxSx}>{renderItems([item], state)}</Box>))}
                        </Box>
                    )
                case "element":
                    return (
                        <Box key={j}>
                            {item.element}
                        </Box>
                    )
                case "heading":
                    return (
                        <Box key={j} sx={item.sx}>
                            <Typography variant="body1" sx={{ fontSize: 18, mt: 2, borderBottom: '1px solid #ccc', pb: 0.5, fontWeight: 'bold' }}>{T(item.label)}</Typography>
                            {item.items && renderItems(item.items, state)}
                        </Box>
                    )
                case "keyring":
                    return (
                        <KeyRingItem {...inputDefaults}
                            key={j}
                            field={item.field}
                            name={item.key}
                            label={T(item.label)}
                            description={T(item.description)}
                            rightElement={item.rightElement}
                            state={state}
                            underline={(item.underline !== undefined) ? (item.underline) : (j < items.length - 1)}
                        />
                    )
                case "select":
                    return (
                        <SelectInput {...inputDefaults}
                            key={j}
                            name={item.name}
                            label={T(item.label)}
                            state={state}
                            options={item.options} 
                            defaultValue={item.defaultValue}
                            onChange={item.onChange}
                            className={item.className}
                            sx={item.sx}
                        />
                    )
                case "text":
                    return (
                        <TextInput {...inputDefaults}
                            key={j}
                            name={item.name}
                            label={T(item.label)}
                            state={state}
                            inputProps={{ maxLength: item.maxLength }}
                            validate={item.validate}
                        />
                    )
                default:
                    return (<Typography key={j}>UNKNOWN TYPE: {item.type}</Typography>)
            }
        });
    }

    const filterInclude = (item: any) => {
        if (item.include !== undefined) {
            if (typeof item.include === 'function') { 
                if (!item.include()) { return false; }
            }
            else {
                if (!item.include) { return false; }
            }
        }
        if (item.items === undefined) {
            if (search && item.label) {
                const s = search.toLowerCase();
                if (item.title && item.title.toLowerCase().indexOf(s) !== -1) { return true; }
                const l = T(item.label).toLowerCase() + (item.description ? ' ' + T(item.description).toLowerCase() : '');
                if (l.indexOf(s) === -1) { return false; }
            }
        }
        else {
            item.items = item.items.filter(filterInclude);
            if (item.items.length === 0) { return false; }
        }
        return true;
    }

    const sections = config.filter(filterInclude);

    return (Api.user.userType > 2) ? (
        <NoAccess />
    ) : (
        <Box maxWidth="md" sx={{ mx: 'auto' }}>
            <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                <Typography variant="h5" flex={1}>
                    {T("Business Configuration")}
                </Typography>
                <Box className="searchbox">
                    <TextField
                        value={search} 
                        className={(search.length ? "expanded-search" : props.isMobile ? "expandable-search" : "")}
                        onChange={(e) => { setSearch(e.target.value); }}
                        margin="normal" size="small" variant="outlined" 
                        slotProps={{ input: { startAdornment: <InputAdornment position="start"><Search02Icon size={20} /></InputAdornment> } }}
                        sx={{ m: 0, '& .MuiOutlinedInput-root': { borderRadius: '50px', }}} 
                    />
                </Box>
                <IconButton disabled={!search} sx={{ ml: 1 }} onClick={() => {
                    clipboardSetText('https://' + window.location.hostname + window.location.pathname + '#s:' + encodeURIComponent(search));
                }}><Share08Icon size={20} /></IconButton>
            </Box>

            {(sections.length === 0) ? (
                <Typography variant="h6" mt={7}>{T("No configuration options match that search.")}</Typography>
            ) : (
                sections.map((section: any, i: number) => (section.heading) ? (
                    <Typography key={i} variant="h6" fontWeight="bold" gutterBottom mt={7}>
                        {T(section.heading)}
                    </Typography>
                ) : (
                    <PaperCollapse key={i} title={T(section.title)} icon={section.icon} open={defSection ? (defSection.toLowerCase() === section.title.replaceAll(' ', '').toLowerCase()) : false} className={section.className}>
                        {renderItems(section.items, section.state)}
                        <ActionButtons
                            onSave={saveData} 
                            state={section.state} 
                        />
                    </PaperCollapse>)
                ))
            }
        </Box>
    );
}
